import React from 'react';

import { useAccount } from '@lib/hooks';


function ExtensionsOptGroup({ label='Extensions' }) {
    const { model } = useAccount();
    const extensions = model.getExtensions();

    return extensions.length < 1 ? '' : (
        <optgroup label={label}>
            {extensions.map(item => (
                <option
                    key={item}
                    value={item}
                >{item}</option>
            ))}
        </optgroup>
    );
}


export default ExtensionsOptGroup;
