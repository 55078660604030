import { actions, assign, createMachine } from 'xstate';
import _ from 'underscore';

import api from '@lib/api';
import constants from '@lib/constants';
import { getGeoCountry } from '@lib/geo-country';

import OnboardingAccountModel from './onboarding-account-model';
import PriceCollection from './price-collection';
import { PriceConnector } from './connectors';


const VERIFY_POLL_INTERVAL_SECS = 20;

const navigationEvents = {
    'NAVIGATE-INTRO': 'intro-page',
    'NAVIGATE-PLAN': 'plan-page',
    'NAVIGATE-BROADBAND': 'broadband-page',
    'NAVIGATE-NUMBER': 'number-page',
    'NAVIGATE-PORTING': 'porting-page',
    'NAVIGATE-HARDWARE': 'hardware-page',
    'NAVIGATE-CONTACT': 'contact-page',
    'NAVIGATE-PAYMENT': 'payment-page',
    'NAVIGATE-COMPLETE': 'complete-page',
};

const nextLabels = {
    'plan-page': 'Choose Your Plan',
    'broadband-page': 'Broadband',
    'number-page': 'Numbers',
    'porting-page': 'Port Numbers',
    'hardware-page': 'Hardware',
    'contact-page': 'Your Details',
    'payment-page': 'Order Summary',
    'complete-page': 'Complete Account Setup',
};


function _skipCommonSteps(skipNofication) {
    return assign((context, event) => {
        const activePlan = context.accountModel.get('plan');
        let { visitedPages } = context;

        if (skipNofication === 'skip-number') {
            visitedPages = ['plan-page', 'porting-page', 'number-page'];
            if (context.accountModel.hasHardware) {
                visitedPages.push('hardware-page');
            }
        } else if (skipNofication === 'skip-plan') {
            visitedPages = ['plan-page'];
        }

        if (skipNofication === 'skip-plan' && !activePlan) {
            const plan = context.priceCollection.getProduct('ext')
            context.accountModel.addProduct(plan, 0);
            context.accountModel.saveValid();
            return { visitedPages, notify: skipNofication };
        } else {
            return { visitedPages };
        }
    });
}


function initializeData(context, event) {
    return new Promise((resolve, reject) => {
        const accountModel = new OnboardingAccountModel();
        const priceCollection = new PriceCollection();
        const priceConnector = new PriceConnector(priceCollection, accountModel);

        let accountModelLoaded = false;
        let priceCollectionLoaded = false;
        let geoCountryLoaded = false;
        let contextData = {};

        // Poll every .25 seconds until all the data is loaded
        const intervalId = setInterval(() => {
            if (accountModelLoaded && priceCollectionLoaded && geoCountryLoaded) {
                priceConnector.connect();
                clearInterval(intervalId);
                resolve(contextData);
            }
        }, 250);

        accountModel.context = { account: context.accountNumber };
        accountModel.once('sync', () => {
            contextData = {
                ...contextData,
                accountModel,
            };
            accountModelLoaded = true;
        }).fetch();

        priceCollection.once('sync', () => {
            contextData = {
                ...contextData,
                priceCollection,
            };
            priceCollectionLoaded = true;
        }).fetch();

        getGeoCountry()
            .then((geoCountry) => {
                contextData = { ...contextData, geoCountry };
                geoCountryLoaded = true;
            })
            .catch(() => geoCountryLoaded = true );
    });
}

const checkOnboardingStatus = (context) => api.v1.get(`onboarding/accounts/${context.accountNumber}/status`, 'json')

const guards = {
    isPlan: (context, event) => event.product.type === 'plan',
    isBuyNumber: (context, event) => event.product.type === 'number',
    isHardware: (context, event) => event.product.type === 'hardware',
    isBroadband: (context, event) => event.product.type === 'broadband',
    isResidential: (context) => context.accountModel.isResidential,
    canSkipToNumbers: ({ accountModel: acc }) => {
        return (acc.isResidential && acc.hasResidentialPlan) ||
               (acc.isBusiness && acc.hasBusinessPlan);
    },
    canSkipToContactDetails: ({ accountModel: acc }) => {
        const canSkipResi = acc.isResidential && acc.hasResidentialPlan &&
            acc.hasAnyNumber && acc.isBasketResidentialCompatible && !acc.needsHardwareWarning;
        const canSkipBusiness = acc.isBusiness && acc.hasBusinessPlan && acc.hasAnyNumber;
        return canSkipResi || canSkipBusiness;
    },
    hasPorting: (context) => context.showPorting,
    hasHardware: (context) => context.accountModel.hasHardware,
    hasBasket: (context) => context.accountModel.basketCount > 0,
    hasBroadband: (context) => context.accountModel.get('broadband').length > 0,
};

const machine = createMachine({
    id: 'app',
    initial: 'checking-user',

    context: {
        accountModel: null,
        priceCollection: null,
        showPorting: false,
        notify: null,
        geoCountry: null,
        navNextLabel: '',
        visitedPages: [],
        approvalStatus: null,
    },

    on: {
        LOGOUT: {
            actions: () => {
                api.v1.post('auth/logout', {})
                    .catch(() => {})
                    .finally(() => window.location.href = `${constants.BASE_VF_URL}/login`);
            },
        },
    },

    states: {
        'checking-user': {
            tags: 'loading',
            invoke: {
                src: () => api.v1.get('auth/status', 'json'),
                onDone: [
                    {
                        actions: () => window.location.href = `${constants.BASE_VF_URL}/login`,
                        cond: (context, event) => event.data.type === 'anonymous',
                    },
                    {
                        actions: () => window.location.href = constants.BASE_CP_URL,
                        cond: (context, event) => event.data.type === 'customer',
                    },
                    {
                        target: 'fetching-data',
                        actions: assign((context, { data }) => ({
                            accountNumber: data.account,
                        })),
                        cond: (context, event) => {
                            return !context.accountNumber;
                        },
                    },
                    {
                        target: 'invalid-user',
                        cond: (context, { data }) => {
                            return context.accountNumber !== data.account;
                        },
                    },
                    {
                        target: 'fetching-data',
                    },
                ],
                onError: {
                    actions: () => {
                        window.location.href = `${constants.BASE_VF_URL}/login`;
                    },
                },
            },
        },

        'invalid-user': {
            type: 'final',
        },

        'fetching-data': {
            tags: 'loading',
            invoke: {
                src: 'initializeData',
                onDone: {
                    target: 'checking-onboarding-status',
                    actions: assign((context, { data }) => {
                        const { accountAddress, billingAddress, invoiceAddress, deliveryAddress } = data.accountModel.attributes;
                        if (!accountAddress.get('country')) accountAddress.set('country', data.geoCountry);
                        if (!billingAddress.get('country')) billingAddress.set('country', data.geoCountry);
                        if (!invoiceAddress.get('country')) invoiceAddress.set('country', data.geoCountry);
                        if (!deliveryAddress.get('country')) deliveryAddress.set('country', data.geoCountry);
                        return {
                            ...data,
                            showPorting: data.accountModel.get('portNumbers').length > 0,
                        };
                    }),
                },
            },
        },

        'checking-onboarding-status': {
            tags: 'loading',
            invoke: {
                src: 'checkOnboardingStatus',
                onDone: [
                    { target: 'intro-page', cond: (context, event) => event.data.status === 'active' },
                    { target: 'verify-email-page', cond: (context, event) => event.data.status === 'verify' },
                    { target: 'contact-us-page', cond: (context, event) => event.data.status === 'contact' },
                    {
                        target: 'complete-page',
                        cond: (context, event) => event.data.status === 'pending' || event.data.status === 'complete',
                        actions: assign((context, event) => ({ approvalStatus: event.data.status })),
                    },
                ],
            },
        },

        'intro-page': {
            tags: 'with-nav',
            entry: actions.choose([
                { cond: 'canSkipToContactDetails', actions: assign({ navNextLabel: nextLabels['contact-page'] }) },
                { cond: 'canSkipToNumbers', actions: assign({ navNextLabel: nextLabels['number-page'] }) },
                { actions: assign((context, event) => ({ navNextLabel: nextLabels['plan-page'] })) },
            ]),
            exit: ['CLEAR-NOTIFICATIONS'],
            on: {
                ..._.omit(navigationEvents, 'NAVIGATE-INTRO'),
                'NAVIGATE-NEXT': [
                    { target: 'contact-page', cond: 'canSkipToContactDetails' },
                    { target: 'number-page', cond: 'canSkipToNumbers' },
                    {
                        target: 'plan-page',
                        actions: assign((context, event) => ({
                            showPorting: context.accountModel?.get('portNumbers')?.length > 0,
                        })),
                    },
                ],
                'SKIP': [
                    {
                        target: 'contact-page',
                        actions: _skipCommonSteps('skip-number'),
                        cond: 'canSkipToContactDetails',
                    },
                    {
                        target: 'number-page',
                        actions: _skipCommonSteps('skip-plan'),
                        cond: 'canSkipToNumbers',
                    },
                ],
            },
        },

        'plan-page': {
            tags: 'with-nav',
            entry: actions.choose([
                { cond: 'hasBroadband', actions: assign({ navNextLabel: nextLabels['broadband-page'] }) },
                { cond: 'hasPorting', actions: assign({ navNextLabel: nextLabels['porting-page'] }) },
                { actions: assign({ navNextLabel: nextLabels['number-page'] }) },
            ]),
            exit: ['SET-VISITED', 'SAVE-ACCOUNT-MODEL', 'CLEAR-NOTIFICATIONS'],
            on: {
                ..._.omit(navigationEvents, 'NAVIGATE-PLAN'),
                'NAVIGATE-NEXT': [
                    { target: 'broadband-page', cond: 'hasBroadband' },
                    { target: 'porting-page', cond: 'hasPorting' },
                    { target: 'number-page' },
                ],
                'SKIP': [
                    { target: 'broadband-page', cond: 'hasBroadband', actions: _skipCommonSteps('skip-plan') },
                    { target: 'porting-page', cond: 'hasPorting', actions: _skipCommonSteps('skip-plan') },
                    { target: 'number-page', actions: _skipCommonSteps('skip-plan') },
                ],
                'SWITCH-RESIDENTIAL': {
                    actions: assign({
                        navNextLabel: nextLabels['number-page'],
                        showPorting: false,
                    }),
                },
            },
        },

        'broadband-page': {
            tags: 'with-nav',
            entry: actions.choose([
                { cond: 'hasPorting', actions: assign({ navNextLabel: nextLabels['porting-page'] }) },
                { actions: assign({ navNextLabel: nextLabels['number-page'] }) },
            ]),
            exit: ['SET-VISITED', 'SAVE-ACCOUNT-MODEL', 'CLEAR-NOTIFICATIONS'],
            on: {
                'NAVIGATE-NEXT': [
                    { target: 'porting-page', cond: 'hasPorting' },
                    { target: 'number-page' },
                ],
                ..._.omit(navigationEvents, 'NAVIGATE-BROADBAND'),
            },
        },

        'porting-page': {
            tags: 'with-nav',
            entry: actions.choose([
                {
                    cond: (context) => {
                        return guards.isResidential(context) && guards.hasHardware(context);
                    },
                    actions: assign({
                        navNextLabel: nextLabels['hardware-page'],
                    }),
                },
                { cond: 'isResidential', actions: assign({ navNextLabel: nextLabels['contact-page'] }) },
                { actions: assign({ navNextLabel: nextLabels['number-page'] }) },
            ]),
            exit: ['SET-VISITED', 'SAVE-ACCOUNT-MODEL', 'CLEAR-NOTIFICATIONS'],
            on: {
                ..._.omit(navigationEvents, 'NAVIGATE-PORTING'),
                'NAVIGATE-NEXT': [
                    {
                        target: 'hardware-page',
                        cond: (context) => {
                            return guards.isResidential(context) && guards.hasHardware(context);
                        },
                    },
                    { target: 'contact-page', cond: 'isResidential' },
                    { target: 'number-page' },
                ],
                'STOP-PORTING': {
                    target: 'number-page',
                    actions: assign((context, event) => ({
                        showPorting: context.accountModel?.get('portNumbers')?.length > 0,
                    })),
                },
            },
        },

        'number-page': {
            tags: 'with-nav',
            entry: actions.choose([
                { cond: 'hasHardware', actions: assign({ navNextLabel: nextLabels['hardware-page'] }) },
                { actions: assign({ navNextLabel: nextLabels['contact-page'] }) },
            ]),
            exit: ['SET-VISITED', 'SAVE-ACCOUNT-MODEL', 'CLEAR-NOTIFICATIONS'],
            on: {
                ..._.omit(navigationEvents, 'NAVIGATE-NUMBER'),
                'NAVIGATE-NEXT': [
                    { target: 'hardware-page', cond: 'hasHardware' },
                    { target: 'contact-page' },
                ],
                'START-PORTING': {
                    target: 'porting-page',
                    actions: assign({ showPorting: true }),
                },
                'STOP-PORTING': {
                    actions: assign({ showPorting: false }),
                },
            },
        },

        'hardware-page': {
            tags: 'with-nav',
            entry: assign({ navNextLabel: nextLabels['contact-page'] }),
            exit: ['SET-VISITED', 'SAVE-ACCOUNT-MODEL', 'CLEAR-NOTIFICATIONS'],
            on: {
                ..._.omit(navigationEvents, 'NAVIGATE-HARDWARE'),
            },
        },

        'contact-page': {
            tags: 'with-nav',
            entry: actions.choose([
                { cond: 'hasBasket', actions: assign({ navNextLabel: nextLabels['payment-page'] }) },
                { actions: assign({ navNextLabel: nextLabels['complete-page'] }) },
            ]),
            exit: ['SET-VISITED', 'SAVE-ACCOUNT-MODEL', 'CLEAR-NOTIFICATIONS'],
            on: {
                ..._.omit(navigationEvents, 'NAVIGATE-CONTACT'),
                'NAVIGATE-NEXT': [
                    { target: 'payment-page', cond: 'hasBasket' },
                    { target: 'checking-onboarding-status' },
                ],
            },
        },

        'verify-email-page': {
            invoke: {
                src: (context) => (callback) => {
                    const interval = setInterval(async () => {
                        const { status } = await checkOnboardingStatus(context);

                        if (status !== 'verify') {
                            callback('VERIFIED');
                        }
                    }, VERIFY_POLL_INTERVAL_SECS * 1000);

                    return () => clearInterval(interval);
                },
            },
            on: {
                'RESEND': {
                    actions: (context) => {
                        api.v1.post('onboarding/accounts/verify/resend', {});
                    },
                },
                'VERIFIED': 'checking-onboarding-status',
            },
        },

        'contact-us-page': {},

        'payment-page': {
            tags: 'with-nav',
            exit: ['SET-VISITED', 'CLEAR-NOTIFICATIONS'],
            on: {
                ..._.omit(navigationEvents, 'NAVIGATE-PAYMENT'),
                'NAVIGATE-EDIT': [
                    { target: 'plan-page', cond: 'isPlan' },
                    { target: 'number-page', cond: 'isBuyNumber' },
                    { target: 'hardware-page', cond: 'isHardware' },
                    { target: 'broadband-page', cond: 'isBroadband' },
                ],
                'NAVIGATE-PREV': 'contact-page',
                'NAVIGATE-NEXT': 'checking-onboarding-status',
                'NAVIGATE-CONTACT-US': 'contact-us-page',
            },
        },

        'complete-page': {
            on: {
                'NAVIGATE-CONTROL-PANEL': {
                    actions: (context) => {
                        api.v1.get(`onboarding/accounts/${context.accountNumber}/transfer`)
                            .then(() => {
                                window.location.href = constants.BASE_CP_URL;
                            });
                    },
                },
            },
        },
    },
}, {
    actions: {
        'CLEAR-NOTIFICATIONS': assign({ notify: null }),
        'SAVE-ACCOUNT-MODEL': (context) => {
            context.accountModel?.saveValid();
        },
        'SET-VISITED': assign((context, event, meta) => ({
            visitedPages: _.unique([...context.visitedPages, meta.state.value]),
        })),
    },
    guards,
    services: {
        initializeData,
        checkOnboardingStatus,
    },
});


export default machine;
