import BroadbandConfigurationModel from '@content/broadband-page/broadband-configuration-model';
import HardwareConfigurationModel from '@content/hardware-page/hardware-configuration-model';
import constants from '@lib/constants';

const VALID_NUMBER_DISCOUNTS = ['01', '02', '03'];


class Product {
    type = 'undefined'
    _configurationCls = null
    sku = null
    renewalSku = null
    description = ''
    oneOffPrice = 0
    servicePrice = 0
    renewalPrice = 0
    minQuantity = 0
    totalLines = 0
    totalStock = 0
    contractLength = 0
    inclusiveServices = []
    isExtension = false
    isTrialPlan = false
    isResidentialPlan = false
    hasDelivery = false
    partnerCode = null

    constructor(sku, renewalSku, description, oneOffPrice, servicePrice, renewalPrice,
            minQuantity, totalLines, totalStock, contractLength,
            inclusiveServices, isExtension, isTrialPlan, isResidentialPlan, hasDelivery, partnerCode) {
        this.sku = sku;
        this.renewalSku = renewalSku;
        this.description = description;
        this.oneOffPrice = oneOffPrice;
        this.servicePrice = servicePrice;
        this.renewalPrice = renewalPrice;
        this.minQuantity = minQuantity;
        this.totalLines = totalLines;
        this.totalStock = totalStock;
        this.contractLength = contractLength;
        this.inclusiveServices = inclusiveServices;
        this.isExtension = isExtension;
        this.isTrialPlan = isTrialPlan;
        this.isResidentialPlan = isResidentialPlan;
        this.hasDelivery = hasDelivery;
        this.partnerCode = partnerCode;
    }

    get uid() {
        return this.sku;
    }

    get name() {
        return this.description;
    }

    get configurationCls() {
        return this._configurationCls;
    }

    get hasVat() {
        return this.sku !== 'newcard';
    }

    get price() {
        return this.servicePrice || this.oneOffPrice;
    }

    get payTodayPrice() {
        return this.oneOffPrice + this.servicePrice;
    }

    get oneOffPriceIncVat() {
        return this.oneOffPrice * constants.VAT_RATE;
    }

    get servicePriceIncVat() {
        return this.servicePrice * constants.VAT_RATE;
    }

    get renewalPriceIncVat() {
        return this.renewalPrice * constants.VAT_RATE;
    }

    get maxQuantity() {
        return this.totalStock;
    }

    isEqual(product) {
        return product.sku === this.sku;
    }

    isRelated(product) {
        return false;
    }
}


class PlanProduct extends Product {
    type = 'plan'

    get shortName() {
        const parts = this.description.split(' ');
        const index = this.isResidential ? 2 : 1;
        return this.isFlex ? 'Flex' : parts.slice(index).join(' ');
    }

    get isFlex() {
        return !this.inclusiveServices;
    }

    get isResidential() {
        return this.isResidentialPlan;
    }
}


class NumberProduct extends Product {
    type = 'number'
    number = null

    get uid() {
        return this.number ? `${this.sku},${this.number}` : this.sku;
    }

    get name() {
        return this.description.replace('%', this.number);
    }

    get isGeoNumber() {
        return this.sku.startsWith('voiceukgeo') && VALID_NUMBER_DISCOUNTS.includes(this.number.slice(0, 2));
    }

    create(number) {
        const n = new this.constructor(this.sku, this.renewalSku,
            this.description, this.oneOffPrice, this.servicePrice, this.renewalPrice,
            this.minQuantity, this.totalLines, this.totalStock,
            this.contractLength, this.inclusiveServices, this.isExtension,
            this.isTrialPlan, this.hasDelivery);
        n.number = number;
        return n;
    }

    isEqual(product) {
        return product.sku === this.sku && product.number === this.number;
    }

    isRelated(product) {
        return product.number && product.number === this.number;
    }
}


class HardwareProduct extends Product {
    type = 'hardware'
    _configurationCls = HardwareConfigurationModel

    get configurationCls() {
        return this.totalLines > 0 ? this._configurationCls : null;
    }

    get imageUrl() {
        return `${constants.BASE_MEDIA_HW_URL}/${this.sku.toLowerCase()}.png`;
    }
}


class BroadbandProduct extends NumberProduct {
    type = 'broadband'
    _configurationCls = BroadbandConfigurationModel
}


class FeeProduct extends NumberProduct {
    type = 'fee'
}


export {
    Product,
    PlanProduct,
    NumberProduct,
    HardwareProduct,
    BroadbandProduct,
    FeeProduct,
};
