import React from 'react';
import { ListGroup } from 'react-bootstrap';

import { useBasket } from '@lib/hooks';

import BasketNumber from './basket-number';


function NumberSidebar({ numbers }) {
    const basket = useBasket();

    return (
        <React.Fragment>
            <h5>Your Numbers</h5>
            <ListGroup as="ul">
                {numbers.map(item => (
                    <BasketNumber
                        key={item.product.uid}
                        number={item.product.number}
                        onDelete={() => basket.remove(item.product)}
                    />
                ))}
            </ListGroup>
        </React.Fragment>
    );
}


export default NumberSidebar;
