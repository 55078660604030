import { useContext, useState } from 'react';
import { Model } from 'backbone';
import _ from 'underscore';

import api from '@lib/api';
import { AppContext } from '@lib/store';

import useDidMount from './use-did-mount';
import useWillUnmount from './use-will-unmount';


class MultiLoader extends Model {
    _endpoints = []
    _listeners = []
    _data = []
    _pending = []

    add(modelOrColl, fetch) {
        if (fetch) {
            this._endpoints = _.union(
                this._endpoints,
                this._getListenerEndpoints(modelOrColl),
            );
        }

        if (!_.contains(this._listeners, modelOrColl)) {
            this._listeners.push(modelOrColl);
        }
    }

    load = () => {
        const originalEndpoints = _.uniq(_.compact(this._endpoints));
        const endpoints = _.difference(originalEndpoints, _.keys(this._data));

        if (endpoints.length > 0) {
            this.trigger('loading');
            api.v0.get(endpoints.join('&'))
                .then(([, data]) => this._notifyLoaded(data))
                .catch(err => console.log('Error', err)); // @TODO: handle errors properly
        } else {
            this._notifyLoaded({});
        }
    }

    _getListenerEndpoints(listener) {
        if (!listener.url) return [];
        const url = _.isFunction(listener.url) ? listener.url() : listener.url;
        return url.split('&');
    }

    _notifyLoaded(data) {
        this._data = _.extend({}, this._data, data);
        this._listeners.forEach(listener => {
            const myData = _.pick(this._data, this._getListenerEndpoints(listener));
            if (_.keys(myData).length > 0) listener.loadWith(myData);
        });
        this.trigger('loaded');
    }
}


function useLoader({ loaderCls, reuseData=false }) {
    const { multiLoader } = useContext(AppContext);
    const [loader,] = useState(new loaderCls());
    const [isLoading, setIsLoading] = useState(true);

    const loadData = () => {
        if (reuseData) _.extend(loader._data, multiLoader._data);

        loader
            .on('loading', () => setIsLoading(true))
            .on('loaded', () => {
                if (reuseData) _.extend(multiLoader._data, loader._data);
                multiLoader._pending.shift();
                _.first(multiLoader._pending)?.trigger('load');
                setIsLoading(false);
            })
            .load();
    };

    useDidMount(() => {
        multiLoader._pending.push(loader);
        loader.once('load', loadData);
        if (multiLoader._pending.length === 1) loader.trigger('load');
    });

    useWillUnmount(() => {
        multiLoader._pending = _.without(multiLoader._pending, loader);
        loader.off();
    });

    return {
        loader,
        isLoading,
        listeners: loader._listeners,
    };
}


export default useLoader;

export {
    useLoader,
    MultiLoader,
};
