import api from '@lib/api';
import { BaseCollection } from '@lib/models';

import PriceModel from './price-model';


class PriceCollection extends BaseCollection {
    url = 'web_shop'
    model = PriceModel

    read = (model, options) => {
        api.v0.get(this.url)
            .then(([status, data]) => this.loadWith(data));
    }

    loadWith(data) {
        this.set(data[this.url].map(this.model.parse), { silent: true });
        this.trigger('sync');
    }

    setTrialPrices() {
        this.filter(item => item.isTrialItem).forEach(item => {
            this.get({ id: item.renewalSku }).set('price', item.get('price'));
        });
    }

    getProducts() {
        return this.map(item => item.getProduct());
    }

    getProduct = (sku) => {
        return this.get(sku)?.getProduct();
    }
}


export default PriceCollection;
