import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { useAccount, useCollection, useSend } from '@lib/hooks';
import { ButtonBar, NextButton, Notifications } from '@components';

import BroadbandOrderForm from './broadband-order-form';
import BroadbandPackageCollection from './broadband-package-collection';
import BroadbandRouterCollection from './broadband-router-collection';

const title = 'Broadband';


function BroadbandPage() {
    const send = useSend();
    const { fixedAttrs: { broadband } } = useAccount();
    const { fixedList: bbPackages, collection: bbPackagesColl } = useCollection(BroadbandPackageCollection);
    const { collection: bbRoutersColl } = useCollection(BroadbandRouterCollection);

    useEffect(() => {
        if (broadband.length === 0) {
            send('NAVIGATE-NEXT');
        }
    }, [broadband]);

    return (
        <div>
            <h2>Your Broadband Package{broadband.length === 1 ? '' : 's'}</h2>

            <Notifications />

            <p>Because our broadband connects you directly into our telephone network we're
                totally in control of its performance - you are not using the public internet
                to make your telephone calls and there's only us to talk to if you have a
                problem with your calls. Like all our services, our broadband is 'no contract'
                - you may leave us at anytime - we don't have silly short-term offers that lock
                you into long-term high price contracts.</p>

            {bbPackages.length > 0 &&
                <Row className="g-4 mb-4">
                    {broadband.map(item => (
                        <Col lg={broadband.length === 1 ? 12 : 6} key={item.product.uid}>
                            <BroadbandOrderForm
                                basketModel={item}
                                packageModel={bbPackagesColl.findWhere({
                                    id: item.configuration.get('package'),
                                })}
                                routerCollection={bbRoutersColl}
                            />
                        </Col>
                    ))}
                </Row>
            }

            <ButtonBar>
                <NextButton onClick={() => send('NAVIGATE-NEXT')} />
            </ButtonBar>
        </div>
    );
}


export default BroadbandPage;

export {
    title,
};
