import api from '@lib/api';
import { BaseCollection } from '@lib/models';

import CountryModel from './country-model';

const BLOCKED_COUNTRIES = [
    'AF',  // Afghanistan
    'BY',  // Belarus
    'CU',  // Cuba
    'IR',  // Iran
    'KP',  // North Korea
    'RU',  // Russia
    'SS',  // South Sudan
    'SD',  // Sudan
    'SY',  // Syria
    'VE',  // Venezuela
];


class CountryCollection extends BaseCollection {
    url = 'countries'
    model = CountryModel

    read = (model, options) => {
        api.v0.get(this.url)
            .then(([status, data]) => this.loadWith(data));
    }

    loadWith(data) {
        this.set(data[this.url].map(this.model.parse).filter(
            country => !BLOCKED_COUNTRIES.includes(country.code2)), { silent: true });
        this.trigger('sync');
    }
}


export default CountryCollection;

export {
    BLOCKED_COUNTRIES,
};
