import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { ButtonBar, NextButton, Notifications, NumberPortingCheckbox, ResidentialNumberPopup } from '@components';
import { useAccount, useBasket, useSend, useStateContext } from '@lib/hooks';

import NumberSearch from './number-search';
import NumberSidebar from './number-sidebar';
import ResidentialBasketNumber from './residential-basket-number';

const title = 'Choose Your Number';


function NumberPage() {
    const send = useSend();
    const { showPorting } = useStateContext();
    const { model: accountModel, fixedAttrs } = useAccount();
    const [showNumberPopup, setShowNumberPopup] = useState(!accountModel.hasAllowedNumbers);
    const basket = useBasket();
    const hasBuyNumber = fixedAttrs.buyNumbers.length > 0;

    useEffect(() => {
        setShowNumberPopup(!accountModel.hasAllowedNumbers);
    }, [fixedAttrs.buyNumbers.length]);

    const onNumberPopupConfirmed = (selectedNumber) => {
        accountModel.setNumber(selectedNumber);
    };

    const onNumberPopupCancelled = () => {
        accountModel.toggleCustomerType();
        send('NAVIGATE-PLAN');
    };

    return (
        <div>
            <h2>Choose Your Number</h2>

            <Notifications />

            {accountModel.isBusiness &&
                <p>If you're a business, phone numbers form part of your brand and
                image. If you already own a number, we can start the transfer
                process or you can pick a new number which is perfect for your
                business here.</p>
            }

            {accountModel.isResidential &&
                <>
                    <p>Choose a phone number using the form below, or select
                    "I already own a number I want to use" if you wish to start
                    the process of transferring your current number.</p>
                    <p><strong>Please note:</strong> you can only buy or transfer
                    one number.</p>
                </>
            }

            {!(hasBuyNumber && accountModel.isResidential) && !showPorting && <NumberPortingCheckbox />}

            {hasBuyNumber && accountModel.isResidential &&
                <React.Fragment>
                    <ResidentialBasketNumber
                        number={fixedAttrs.buyNumbers[0].product.number}
                        onDelete={() => basket.remove(fixedAttrs.buyNumbers[0].product)}
                    />
                    <br />
                </React.Fragment>
            }

            <Row className="g-4 flex-xl-nowrap">
                <Col xl={hasBuyNumber && accountModel.isBusiness ? 8 : 12} style={{ transition: 'width .3s' }}>
                    <NumberSearch />
                </Col>

                {hasBuyNumber && accountModel.isBusiness &&
                    <Col>
                        <NumberSidebar numbers={fixedAttrs.buyNumbers} />
                    </Col>
                }
            </Row>

            <br/>

            <ButtonBar>
                <NextButton
                    onClick={() => send('NAVIGATE-NEXT')}
                    disabled={accountModel.isResidential && !hasBuyNumber}
                />
            </ButtonBar>

            {showNumberPopup &&
                <ResidentialNumberPopup
                    onConfirm={onNumberPopupConfirmed}
                    onCancel={onNumberPopupCancelled}
                />
            }
        </div>
    );
}


export default NumberPage;

export {
    title,
};
