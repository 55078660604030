import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { CheckLg } from 'react-bootstrap-icons';
import _ from 'underscore';

import { useAccount, useBasket } from '@lib/hooks';


function PlanSelectorForm({ plan, trialPlan }) {
    const { model: accountModel, fixedAttrs: { plan: activePlan, hasTrial } } = useAccount();
    const inBasket = activePlan && activePlan?.product.sku === plan.sku;
    const inBasketTrial = activePlan && activePlan?.product.sku === trialPlan?.sku;
    const [seats, setSeats] = useState((inBasket || inBasketTrial) ? activePlan.quantity : 1);
    const basket = useBasket();
    const hideTrial = !hasTrial && plan.isFlex;

    useEffect(() => {
        if (inBasket || inBasketTrial) {
            activePlan.set('quantity', seats);
        }
    }, [seats]);

    const onSelectPlan = (e) => {
        if (activePlan?.product.isTrialPlan){
            if (!inBasketTrial) {
                basket.toggle(hideTrial ? plan : trialPlan, seats);
            }
        } else if (!inBasket) {
            basket.toggle(plan, seats);
        }
        setSeats(parseInt(e.target.value));
    }

    return (
        <Form>
            {accountModel.isBusiness &&
                <Form.Select
                    value={seats}
                    onChange={onSelectPlan}
                >
                    {_.range(plan.minQuantity, plan.totalStock + 1).map(i => (
                        <option
                            key={i}
                            value={i}
                            onClick={i === seats ? onSelectPlan : null}
                        >{i} User{i !== 1 && 's'}</option>
                    ))}
                </Form.Select>
            }

            <Button
                variant={inBasket || inBasketTrial ? 'warning' : 'purple'}
                className="w-100 mt-3"
                onClick={() => basket.toggle(plan, seats)}
                data-sku={plan?.sku.split('-')[1]}
            >{inBasket || inBasketTrial ? 'Selected' : 'Select'}</Button>

            {trialPlan && !hideTrial &&
                <Button
                    variant={inBasketTrial ? 'warning' : 'outline-purple'}
                    className="w-100 mt-3 text-nowrap"
                    onClick={() => basket.toggle(trialPlan, seats)}
                    data-sku={trialPlan?.sku.split('-')[1]}
                >{inBasketTrial && <CheckLg/>} Free Trial</Button>
            }
        </Form>
    );
}


export default PlanSelectorForm;
