import React from 'react';
import { Button } from 'react-bootstrap';
import { Pen } from 'react-bootstrap-icons';

import { useSend } from '@lib/hooks';


function BasketEditButton({ product }) {
    const send = useSend();

    return (
        <React.Fragment>
            {product.type !== 'undefined' && product.type !== 'fee' &&
                <Button
                    variant="link text-body"
                    onClick={() => send('NAVIGATE-EDIT', { product })}
                >
                    <Pen className="d-block" />
                </Button>
            }
        </React.Fragment>
    );
}


export default BasketEditButton;
