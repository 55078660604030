import React, { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import _ from 'underscore';

import '@components/date-range-picker/date-range-picker.scss';
import { Picker } from '@components/forms';
import { useBasket, useCollection, useDialog, useModel, usePriceList } from '@lib/hooks';

import BroadbandAddress from './broadband-address';
import BroadbandSetupCosts from './broadband-setup-costs';

const ROUTER_PREFIX = 'bb_router_';
const STATIC_IP = 'bb_static_activation';
const STATIC_IP_PRICE = 2;


function BroadbandOrderForm({ basketModel, packageModel, routerCollection }) {
    const { getProduct } = usePriceList();
    const { fixedAttrs: bbPackage } = useModel(packageModel);
    const { fixedAttrs: orderConfig, model: orderModel } = useModel(basketModel.configuration);
    const { fixedList: routers } = useCollection(routerCollection);
    const routerItems = _.compact(_.values(_.groupBy(routers, 'name')).map(group => {
        const tech = group.find(item => item.connectionType === bbPackage.connectionType);
        const any = group.find(item => item.connectionType === 'any');
        return tech || any;
    }));
    const basket = useBasket();
    const [RemoveOrderDialog, showDialog] = useDialog({
        title: 'Remove Broadband?',
        message: 'Please confirm that you wish to remove this broadband order.',
        onOkClicked: () => {
            const items = basket.getRelated(basketModel.product);
            items.sort((a, b) => a.product.isEqual(basketModel.product) ? 0 : -1);
            items.forEach(item => basket.remove(item.product));
        },
    });

    useEffect(() => {
        const prevRouter = orderModel.previous('router');
        updateBasket({
            removeSku: prevRouter ? ROUTER_PREFIX + prevRouter : null,
            addSku: orderConfig.router ? ROUTER_PREFIX + orderConfig.router : null,
        });
    }, [orderConfig.router]);

    useEffect(() => {
        const hasStaticIp = orderModel.previous('staticIp');
        updateBasket({
            removeSku: hasStaticIp ? STATIC_IP : null,
            addSku: orderConfig.staticIp ? STATIC_IP : null,
        });
    }, [orderConfig.staticIp]);

    const updateBasket = ({ removeSku, addSku }) => {
        if (removeSku) {
            basket.remove(getProduct(removeSku).create(basketModel.product.number));
        }

        if (addSku) {
            basket.add(getProduct(addSku).create(basketModel.product.number));
        }
    };

    return (
        <Card className="border h-100">
            <Card.Body>
                <Row>
                    <Col xs={10}>
                        <Card.Title className="p-0">{bbPackage.description}</Card.Title>
                    </Col>
                    <Col className="text-end">
                        <Button
                            title="Remove broadband order"
                            size="sm"
                            variant="link text-body"
                            onClick={showDialog}
                        >
                            <Trash />
                        </Button>
                    </Col>
                </Row>

                <Card.Subtitle className="mb-2 text-muted">
                    &pound;{bbPackage.price} per month
                </Card.Subtitle>

                <BroadbandSetupCosts basketModel={basketModel} />

                <BroadbandAddress addressModel={orderConfig.address} />

                <div className="mb-3">
                    <p className="mb-2">
                        <strong>Router</strong>
                    </p>

                    <Picker bind={orderConfig} field="router">
                        <Picker.NoneOption />
                        {routerItems.map(item => (
                            <option key={item.sku} value={item.sku}>
                                {item.description} (&pound;{item.price})
                            </option>
                        ))}
                    </Picker>
                </div>

                <div className="mb-3">
                    <p className="mb-2">
                        <strong>Static IP</strong>
                    </p>

                    <Picker bind={orderConfig} field="staticIp" dataType="bool">
                        <option value="true">Yes (&pound;{STATIC_IP_PRICE} per month)</option>
                        <option value="false">No</option>
                    </Picker>
                </div>

                <div className="mb-3">
                    <p className="mb-2">
                        <strong>Installation</strong>
                    </p>

                    <Picker bind={orderConfig} field="requestInstallationDate" dataType="bool">
                        <option value={false}>As soon as possible</option>
                        <option value={true}>Request specific date</option>
                    </Picker>
                </div>

                {orderConfig.requestInstallationDate &&
                    <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        selected={orderConfig.requestedInstallationDate.toDate()}
                        minDate={orderModel.minInstallationDate.toDate()}
                        maxDate={orderModel.maxInstallationDate.toDate()}
                        onChange={date => orderModel.set('requestedInstallationDate', dayjs(date))}
                    />
                }
            </Card.Body>

            <RemoveOrderDialog />
        </Card>
    );
}


export default BroadbandOrderForm;
