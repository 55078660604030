import React, { useEffect, useState } from 'react';
import { LiveChatWidget } from '@livechat/widget-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMachine } from '@xstate/react';

import MainWin from '@components/main-win';
import SimpleWin from '@components/simple-win';
import IntroPage from '@content/intro-page';
import PlanPage from '@content/plan-page';
import BroadbandPage from '@content/broadband-page';
import NumberPage from '@content/number-page';
import PortingPage from '@content/porting-page';
import HardwarePage from '@content/hardware-page';
import ContactPage from '@content/contact-page';
import PaymentPage from '@content/payment-page';
import VerifyEmailPage from '@content/verify-email-page';
import ContactUsPage from '@content/contact-us-page';
import CompletePage from '@content/complete-page';
import analytics from '@lib/analytics';
import constants from '@lib/constants';
import { MultiLoader } from '@lib/hooks';
import { AppContext } from '@lib/store';

import machine from './onboarding-machine';

import './onboarding-account.scss';


function OnboardingAccount() {
    const params = useParams();
    const navigate = useNavigate();
    const [multiLoader,] = useState(new MultiLoader());
    const [state, send] = useMachine(machine, {
        context: { accountNumber: params.account },
    });
    const appContext = {
        context: state.context,
        accountModel: state.context.accountModel,
        priceCollection: state.context.priceCollection,
        multiLoader,
        send,
    };
    const isLoggedIn = !state.hasTag('loading') && !state.done;

    useEffect(() => {
        analytics.sendPageView(`/${state.value}`);
    }, [state.value]);

    useEffect(() => {
        if (state.context.accountNumber) {
            navigate(`/${state.context.accountNumber}`);
        }
    }, [state.context.accountNumber]);

    return (
        <AppContext.Provider value={appContext}>
            {state.hasTag('loading') &&
                <SimpleWin withSpinner>
                    Fetching your account details
                </SimpleWin>
            }

            {state.done &&
                <SimpleWin>
                    You don't have permission to access this account.
                    Try <a href={`${constants.BASE_VF_URL}/login`}>logging in</a> as
                    a different user
                </SimpleWin>
            }

            {state.hasTag('with-nav') && isLoggedIn &&
                <MainWin page={state.value}>
                    { state.matches('intro-page') && <IntroPage /> }
                    { state.matches('plan-page') && <PlanPage /> }
                    { state.matches('broadband-page') && <BroadbandPage /> }
                    { state.matches('number-page') && <NumberPage /> }
                    { state.matches('porting-page') && <PortingPage /> }
                    { state.matches('hardware-page') && <HardwarePage /> }
                    { state.matches('contact-page') && <ContactPage /> }
                    { state.matches('payment-page') && <PaymentPage /> }
                </MainWin>
            }

            {!state.hasTag('with-nav') && isLoggedIn &&
                <SimpleWin>
                    { state.matches('verify-email-page') && <VerifyEmailPage /> }
                    { state.matches('contact-us-page') && <ContactUsPage /> }
                    { state.matches('complete-page') && <CompletePage /> }
                </SimpleWin>
            }

            <LiveChatWidget
                license="1062341"
                sessionVariables={{
                    accountNumber: isLoggedIn ? state.context.accountNumber : null,
                    page: state.value,
                    website: 'Onboarding Portal',
                }}
            />
        </AppContext.Provider>
    );
}


export default OnboardingAccount;
