import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import AddButton from './add-button';
import ResetButton from './reset-button';
import SaveButton from './save-button';


function ButtonPanel({ children }) {
    return (
        <Card bg="light">
            <Card.Body>
                <Row>
                    {children}
                </Row>
            </Card.Body>
        </Card>
    );
}


ButtonPanel.Left =({ size='6', children }) => (
    <Col md={size} className="text-left">
        {React.Children.map(children, child => (
            <React.Fragment>{child}&nbsp;</React.Fragment>
        ))}
    </Col>
);


ButtonPanel.Right = ({ size='6', children }) => (
    <Col md={size} className="text-right text-end">
        {React.Children.map(children, child => (
            <React.Fragment>&nbsp;{child}</React.Fragment>
        ))}
    </Col>
);


ButtonPanel.Default = ({ bind, saveLabel, canReset, canSave, onAdd }) => (
    <ButtonPanel>
        <ButtonPanel.Left>
            <ResetButton bind={bind} canReset={canReset} />
        </ButtonPanel.Left>
        <ButtonPanel.Right>
            <SaveButton canSave={canSave} label={saveLabel} />
            {onAdd &&
                <AddButton onClick={onAdd} />
            }
        </ButtonPanel.Right>
    </ButtonPanel>
);


export default ButtonPanel;
