import React from 'react';
import Select from 'react-select'

import { useCollection } from '@lib/hooks';

import AreaCodeCollection from './area-code-collection';

import './area-code.scss';


function AreaCodeSelect({ loader, onChange }) {
    const { fixedList } = useCollection(AreaCodeCollection, { loader });

    const options = fixedList.map(item => ({
        value: item.prefix,
        label: `${item.prefix} - ${item.destination}`,
    }));

    return <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={options}
        onChange={onChange}
    />;
}


export default AreaCodeSelect;
