import React from 'react';
import { Button, Card } from 'react-bootstrap';

import fmt from '@lib/formatters';
import { useAccount, useBasket, usePriceList } from '@lib/hooks';

const TYPE_ONE_OFF = 'oneoff';


function NumberResult({ baseSku, number, type }) {
    const { model: accountModel } = useAccount();
    const { getProduct } = usePriceList();
    const basket = useBasket();
    const item = getProduct(baseSku + type).create(number);
    const numType = type !== TYPE_ONE_OFF && type;

    const getColour = (baseColour='body', activeColour='light') => {
        return basket.contains(item) ? activeColour : baseColour;
    };

    const onButtonClicked = () => {
        if (item) {
            if (accountModel.isResidential) {
                // Residential customers cannot purchase more than one number
                accountModel.get('buyNumbers')
                    .filter(number => !number.product.isEqual(item))
                    .forEach(number => basket.remove(number.product));
            }
            basket.toggle(item);
        }
    };

    return (
        <Card bg={getColour('light', 'purple')} className="h-100">
            <Card.Body>
                <Button
                    variant="link"
                    className={`number-result text-${getColour()} fw-bold text-decoration-none stretched-link`}
                    onClick={onButtonClicked}
                >
                    {fmt.phoneNumber(number)}
                </Button>

                <p className={`small m-0 text-${getColour('pink')}`}>
                    {item &&
                        <span>
                            &pound;{fmt.price(accountModel.isResidential
                                ? item.renewalPriceIncVat
                                : item.renewalPrice)} per month
                        </span>
                    }

                    <br/>

                    {type && item.oneOffPrice > 0 &&
                        <span>
                            &pound;{fmt.price(accountModel.isResidential
                                ? item.oneOffPriceIncVat
                                : item.oneOffPrice)} {numType} number set-up fee
                        </span>
                    }
                </p>
            </Card.Body>
        </Card>
    );
}


export default NumberResult;
