import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import OnboardingAccount from '@components/onboarding-account';
import VerifyAccount from '@components/verify-account';


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/:account" element={<OnboardingAccount />} />
                <Route path="/verify/:token" element={<VerifyAccount />} />
                <Route path="*" element={<OnboardingAccount />} />
            </Routes>
        </Router>
    );
}


export default App;
