import React, { useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import _ from 'underscore';

import { getErrorMsg } from '@lib/utils';

import useDidMount from './use-did-mount';
import useWillUnmount from './use-will-unmount';

const HEADER_HEIGHT = 80;


function useNotify({ collection, model, successMsg=null, onSuccess=null,
        errorMsgs={}, withScroll=false }) {
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState(null);
    const [eventId,] = useState(_.uniqueId('e'));
    const listener = collection || model;
    const fieldElement = useRef();

    const scrollIntoView = () => {
        const elY = fieldElement?.current?.getBoundingClientRect().top;
        const pageY = window.pageYOffset - HEADER_HEIGHT;
        if (elY) window.scrollTo({ top: elY + pageY, behavior: 'smooth' });
    };

    const resetAlert = () => {
        setMessage(null);
        setMessageType(null);
    };

    useDidMount(() => {
        listener?.on('update', () => {
            setMessage(successMsg);
            setMessageType('success');
            if (onSuccess) onSuccess();
            if (successMsg && withScroll) scrollIntoView();
        }, eventId);

        listener?.on('error', error => {
            setMessage(getErrorMsg(error, errorMsgs));
            setMessageType('danger');
            if (withScroll) scrollIntoView();
        }, eventId);
    });

    useWillUnmount(() => listener?.off(null, null, eventId));

    const AlertComponent = () => (
        message && <Alert
            variant={messageType}
            onClose={() => setMessage(null)}
            ref={fieldElement}
        >
            {message}
        </Alert>
    );

    return [AlertComponent, resetAlert];
}


export default useNotify;
