import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

import { useAccount, useSend } from '@lib/hooks';
import { ButtonBar, NextButton, Notifications, NumberPortingCheckbox } from '@components';

import PortingConfirmationDialog from './porting-confirmation-dialog';
import PortingForm from './porting-form';
import PortingNumbers from './porting-numbers';

const title = 'Port Your Existing Numbers';


function PortingPageBusiness() {
    const send = useSend();
    const { fixedAttrs: { portNumbers } } = useAccount();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const hasPortNumber = portNumbers.length > 0;

    return (
        <div>
            <h2>Port Your Existing Numbers</h2>

            <Notifications />

            <p>Transfer your existing numbers from your old phone company to your Voipfone
            account.</p>

            <p>We can move (port) almost any UK landline number onto our service, including
            BT, Sky, TalkTalk and Virgin Media, as well as numbers from other VoIP providers.
            It's fast, free and will save you a lot of money.</p>

            <p>Enter the numbers you wish to move below and we'll start the process of porting
            them.</p>

            <p><strong>Please note:</strong> to complete the porting process you will need to
            enter some additional information from the Voipfone Control Panel once you've finished
            setting up your account.</p>

            <NumberPortingCheckbox />

            <PortingForm />

            {!hasPortNumber &&
                <Alert variant="secondary" className="text-center">
                    No numbers to port
                </Alert>
            }

            <PortingNumbers />

            <br />

            <ButtonBar>
                <NextButton
                    onClick={() => setShowConfirmation(true)}
                />
            </ButtonBar>

            <PortingConfirmationDialog
                show={showConfirmation}
                backdrop="static"
                onConfirmed={() => send('NAVIGATE-NEXT')}
            />
        </div>
    );
}


export default PortingPageBusiness;

export {
    title,
};
