import React from 'react';
import { Table } from 'react-bootstrap';

import constants from '@lib/constants';
import fmt from '@lib/formatters';
import { useAccount, usePriceList } from '@lib/hooks';
import { SERVICES_BUSINESS as services } from '@lib/services';

import planFmt from './plan-formatters';
import PlanInclusiveService from './plan-inclusive-service';
import PlanSelectorForm from './plan-selector-form';


function BusinessPlansDesktop({ plans }) {
    const { getProduct, getTrialProductOf } = usePriceList();
    const { fixedAttrs: { plan: activePlan } } = useAccount();

    return (
        <Table borderless className="comparison m-auto mb-4">
            <thead>
                <tr>
                    <th className="bg-plan-blue"></th>
                    {plans.map(plan => (
                        <th
                            key={plan.uid}
                            className={`text-center ${plan.partnerCode ? 'offer' : ''} bg-plan-${planFmt.getBgColour(activePlan?.product, plan)}`}
                            style={{ width: '16%' }}
                        >
                            Voipfone<br />{plan.shortName}

                            {plan.partnerCode &&
                                <div className="small">
                                    {plan.partnerCode.toUpperCase()} offer
                                    - {planFmt.getPercentOff(plan, getProduct(plan.renewalSku))}% off
                                    for first {plan.contractLength} months
                                </div>
                            }
                        </th>
                    ))}
                </tr>
                <tr>
                    <td className="bg-plan-blue">
                        Monthly Price
                        <br />
                        <small>(per user)</small>
                    </td>
                    {plans.map(plan => (
                        <td key={plan.uid} className={`text-center h1 ${plan.partnerCode ? 'offer' : ''} bg-plan-${planFmt.getBgColour(activePlan?.product, plan)}`}>
                            &pound;{fmt.price(plan.partnerCode ? plan.price : plan.renewalPrice, true)}
                        </td>
                    ))}
                </tr>
                <tr>
                    <td className="bg-plan-blue-light">
                        Included UK Calls
                        <br />
                        <small>(per user)</small>
                    </td>
                    {plans.map(plan => (
                        <td
                            key={plan.uid}
                            className={`text-center text-nowrap align-top bg-plan-${planFmt.getBgColour(activePlan?.product, plan)}-light`}
                        >
                            {planFmt.getMinutesLabel(plan.inclusiveServices)}
                        </td>
                    ))}
                </tr>
                <tr>
                    <td className="bg-plan-blue-lighter">
                        Included Numbers
                        <br />
                        <small>(per account)</small>
                    </td>
                    {plans.map(plan => (
                        <td
                            key={plan.uid}
                            className={`text-center align-top bg-plan-${planFmt.getBgColour(activePlan?.product, plan)}-lighter`}
                        >
                            {planFmt.getIncludedNumbersLabel(plan.inclusiveServices)}
                        </td>
                    ))}
                </tr>
            </thead>
            <tbody>
                {services.map((service, index) => (
                    <tr
                        key={service.id}
                        className={'border-bottom' + (index + 1 < services.length ? ' border-light' : '')}
                    >
                        <td className="text-nowrap">
                            <a className="fw-bold text-body"
                                href={`${constants.BASE_VF_URL}/${service.url}`}
                                target="_blank" rel="noopener noreferrer">
                                {service.name}
                            </a>
                        </td>
                        {plans.map(plan => (
                            <td
                                key={plan.uid}
                                className={`text-center bg-plan-${planFmt.getBgColour(activePlan?.product, plan)}-lightest`}
                            >
                                <PlanInclusiveService
                                    product={getProduct(service.id)}
                                    inclusiveServices={plan.inclusiveServices}
                                />
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr className="text-center text-nowrap">
                    <td rowSpan="3"></td>
                    {plans.map(plan => (
                        <td key={plan.uid}>
                            {planFmt.getContractLabel(plan)}
                        </td>
                    ))}
                </tr>
                <tr>
                    {plans.map(plan => (
                        <td key={plan.uid} className="align-top">
                            <PlanSelectorForm
                                plan={plan}
                                trialPlan={getTrialProductOf(plan)}
                            />
                        </td>
                    ))}
                </tr>
            </tfoot>
        </Table>
    );
}


export default BusinessPlansDesktop;
