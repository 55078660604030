import React from 'react';
import { Button } from 'react-bootstrap';

import { NotificationAlert } from '@components/notifications';
import constants from '@lib/constants';
import { useSend, useStateContext } from '@lib/hooks';

const title = 'Setup Complete';


function CompletePage() {
    const { approvalStatus } = useStateContext();
    const send = useSend();

    return (
        <div>
            <h1>Setup Complete</h1>

            <p>Thank you for signing up with Voipfone.</p>

            {approvalStatus === 'pending' && (
                <NotificationAlert variant="warning" className="mt-3">
                    Sorry, this is taking longer than expected. We'll send you an
                    email as soon as your account is ready to use. If you haven't
                    received that email within 24 hours, please contact <a target="_blank"
                    href={`${constants.BASE_VF_URL}/contact`}>customer support</a>.
                </NotificationAlert>
            )}

            {approvalStatus === 'complete' && (
                <Button
                    className="mt-3"
                    variant="success"
                    onClick={() => send('NAVIGATE-CONTROL-PANEL')}
                >
                    Go to My Account
                </Button>
            )}
        </div>
    );
}


export default CompletePage;

export {
    title,
};
