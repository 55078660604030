import React from 'react';

import { Notifications, NumberPortingCheckbox } from '@components';

import PortingForm from './porting-form';
import PortingNumbers from './porting-numbers';


function PortingPageResidential() {
    return (
        <div>
            <h2>Transfer Your Existing Number</h2>

            <Notifications />

            <p>Please enter your current phone number below to begin transferring it to Voipfone.</p>

            <NumberPortingCheckbox />

            <PortingForm />

            <PortingNumbers />
        </div>
    );
}


export default PortingPageResidential;
