import Cookies from 'universal-cookie';

import api from '@lib/api';
import { BaseModel } from '@lib/models';

import { STATUS_OK, STATUS_REVIEW, STATUS_VERFIY } from './create-account-model';

const STATUS_ABORT = 'abort';
const STATUS_AUTH = 'notauthed';
const STATUS_DECLINE = 'declined';
const STATUS_INVALID = 'invalid-details';
const STATUS_REJECT = 'rejected';
const CANCELLED_RESPONSES = [STATUS_ABORT];
const FAILED_RESPONSES = [STATUS_DECLINE, STATUS_AUTH, STATUS_REJECT];
const SUCCESSFUL_RESPONSES = [STATUS_OK, STATUS_REVIEW, STATUS_VERFIY];
const VALID_RESPONSES = [STATUS_OK, STATUS_REVIEW, STATUS_ABORT, STATUS_DECLINE,
    STATUS_AUTH, STATUS_REJECT];


class PaymentModel extends BaseModel {
    url = 'payOnboarding'

    static defaults = {
        url: '',
        status: null,
    }

    get analyticsData() {
        return {
            currency: 'GBP',
            transaction_id: new Cookies().get('web-id'),
            value: this.context.accountModel.totalExVat,
            items: this.context.accountModel.getBasketItems().map(item => ({
                item_id: item.product.sku,
                item_name: item.product.name,
                quantity: item.quantity,
                price: item.product.payTodayPrice,
            })),
        };
    }

    get parameters() {
        return {
            account: this.context.accountModel.get('voipfone'),
            hardware: this.context.accountModel.hardwareConfigParameters,
            hardwareQuantity: this.context.accountModel.hardwareCount,
            web_id: new Cookies().get('web-id') || '',
        };
    }

    save = () => {
        api.v0.post(this.url, this.parameters)
            .then(response => {
                const [status, data] = response;
                const [freeStatus, freeData] = response.flat();
                const isFree = freeStatus === 'free';
                if (status !== 'ok' && !isFree) throw response;

                if (isFree) {
                    this.set('status', freeData === STATUS_REVIEW ? STATUS_REVIEW : STATUS_OK);
                } else {
                    this.set({ url: data.url || '', status: '' });
                    new Cookies().set('web-id', data.id || '');
                    this.trigger('sync');
                }
            })
            .catch(([error,]) => {
                if (error === STATUS_INVALID) {
                    this.set('status', STATUS_INVALID);
                } else {
                    this.trigger('error', error);
                }
            });
    }
}


export default PaymentModel;

export {
    CANCELLED_RESPONSES,
    FAILED_RESPONSES,
    STATUS_DECLINE,
    STATUS_INVALID,
    STATUS_OK,
    STATUS_REVIEW,
    SUCCESSFUL_RESPONSES,
    VALID_RESPONSES,
};
