import _s from 'underscore.string';

import { BaseModel } from '@lib/models';
import { PlanProduct, NumberProduct, Product, HardwareProduct, BroadbandProduct, FeeProduct } from '@lib/products';

const SPECIAL_NUMBER_TYPES = ['platinum', 'gold', 'silver', 'bronze', 'special', 'oneoff'];
const TRIAL_SUFFIX = '_free';
const PACKAGED_NUMBER_REFUND_ID = 'packagedNumber_discount';


class PriceModel extends BaseModel {
    product = null

    static defaults = {
        id: '',
        description: '',
        price: 0,
        monthlyPrice: 0,
        minQuantity: 0,
        totalStock: 0,
        totalLines: 0,
        hasDelivery: false,
        isExtension: false,
        isTrialPlan: false,
        isResidentialPlan: false,
        _renewalSku: null,
        inclusiveServices: null,
        contractLength: 0,
        partnerCode: null,
    }

    static parse(data) {
        return {
            id: String(data.id),
            description: String(data.description),
            price: parseInt(data.price) / 100,
            monthlyPrice: parseInt(data.monthly_price) / 100,
            minQuantity: parseInt(data.min_quantity),
            totalStock: parseInt(data.total_stock),
            totalLines: parseInt(data.total_lines),
            hasDelivery: Boolean(data.has_delivery),
            isExtension: Boolean(data.is_extension),
            isTrialPlan: Boolean(data.is_trial_plan),
            isResidentialPlan: Boolean(data.is_residential_plan),
            _renewalSku: data.id.indexOf(TRIAL_SUFFIX) > -1 ?
                data.id.replace(TRIAL_SUFFIX, '') : data.renewal_sku,
            inclusiveServices: data.inclusive_services,
            contractLength: parseInt(data.contract_length),
            partnerCode: data.partner_code,
        };
    }

    get isPackagedNumberRefund() {
        return this.get('id') === PACKAGED_NUMBER_REFUND_ID;
    }

    get isTrialItem() {
        return this.get('id').indexOf(TRIAL_SUFFIX) > -1;
    }

    get renewalSku() {
        const { id, _renewalSku } = this.attributes;
        const numberType = this.getSpecialNumberType();
        return numberType ? id.replace(numberType, '') : _renewalSku;
    }

    get oneOffPrice() {
        const { monthlyPrice, price } = this.attributes;
        return (!this.isPackagedNumberRefund && monthlyPrice === 0) ? price : 0;
    }

    get servicePrice() {
        // Return the price if there's a monthly price (otherwise it's a one off) or it is part of a trial
        // Otherwise, check the renewal SKU price (for special numbers)
        const { monthlyPrice, price } = this.attributes;
        if (this.isPackagedNumberRefund || monthlyPrice !== 0 || this.isTrialItem) return price;
        return this.renewalSku ? this.collection.get(this.renewalSku).get('price') : 0;
    }

    get renewalPrice() {
        // Return the service price if the contract length is > 1 (offers)
        // Otherwise, check the renewal SKU monthly price (for special numbers)
        const { contractLength, monthlyPrice, price } = this.attributes;
        if (contractLength > 1 || this.isPackagedNumberRefund) return price;
        return this.renewalSku ? this.collection.get(this.renewalSku).get('monthlyPrice') : monthlyPrice;
    }

    getSpecialNumberType() {
        return this.getProductType() === NumberProduct &&
            SPECIAL_NUMBER_TYPES.find(type => _s.endsWith(this.get('id'), type));
    }

    getProductType() {
        const data = this.attributes;
        let type = Product;

        if (data.isExtension) {
            if (_s.startsWith(data.id, 'ext')) data.description = 'Voipfone Flex';
            type = PlanProduct;
        } else if (data.hasDelivery) {
            type = HardwareProduct;
        } else if (_s.startsWith(data.id, 'bb_activation')) {
            type = BroadbandProduct;
        } else if (_s.startsWith(data.id, 'voice') || _s.startsWith(data.id, 'fax')) {
            type = NumberProduct;
        } else if (data.monthlyPrice === 0) {
            type = FeeProduct;
        }

        return type;
    }

    getProduct() {
        if (this.product) return this.product;
        const data = this.attributes;
        const type = this.getProductType();
        this.product = new type(data.id, this.renewalSku, data.description, this.oneOffPrice,
            this.servicePrice, this.renewalPrice, data.minQuantity, data.totalLines, data.totalStock,
            data.contractLength, data.inclusiveServices, data.isExtension,
            data.isTrialPlan, data.isResidentialPlan, data.hasDelivery, data.partnerCode);
        return this.product;
    }
}


export default PriceModel;

export {
    PACKAGED_NUMBER_REFUND_ID,
};
