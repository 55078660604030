import api from '@lib/api';
import { BaseCollection } from '@lib/models';
import _ from 'underscore';

import StateModel from './state-model';


class StateCollection extends BaseCollection {
    url = 'states'
    model = StateModel

    comparator = model => model.get('name');

    read = (model, options) => {
        api.v0.get(this.url)
            .then(([status, data]) => this.loadWith(data));
    }

    loadWith(data) {
        const countryCodes = _.keys(data[this.url]);
        const statesArrays = countryCodes.map(countryCode => {
            const states = data[this.url][countryCode];
            return _.keys(states).map(stateCode => (
                [countryCode, stateCode, states[stateCode]]
            ));
        });

        data = _.flatten(statesArrays, 1);

        this.set(data.map(this.model.parse));
        this.trigger('sync');
    }
}


export default StateCollection;
