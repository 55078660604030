import React from 'react';
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';

import { useStateContext } from '@lib/hooks';


function NextButton(props) {
    const context = useStateContext();

    return (
        <Button variant="success" {...props}>
            Next: {context.navNextLabel} <ChevronRight />
        </Button>
    );
}


export default NextButton;
