import { BaseModel } from '@lib/models';


class BroadbandPackageModel extends BaseModel {
    static defaults = {
        id: 0,
        name: '',
        description: '',
        price: 0,
        connectionType: '',
    }

    static parse(data) {
        return {
            id: parseInt(data.id),
            name: String(data.name),
            description: String(data.description),
            price: parseInt(data.price) / 100,
            connectionType: String(data.connection_type),
        };
    }
}


export default BroadbandPackageModel;
