import api from '@lib/api';
import { BaseModel } from '@lib/models';

const STATUS_OK = 'ok';
const STATUS_REVIEW = 'review';
const STATUS_VERFIY = 'verify';


class CreateAccountModel extends BaseModel {
    static defaults = {
        status: null,
    }

    get analyticsData() {
        return { method: 'Onboarding' };
    }

    url = () => `onboarding/accounts/${this.context.accountModel.get('voipfone')}/activate`

    save = () => {
        api.v1.post(this.url())
            .then(() => {
                this.set('status', STATUS_OK);
                this.trigger('sync');
            })
            .catch(err => {
                switch (err.error) {
                    case 'verification-required':
                        this.set('status', STATUS_VERFIY);
                        break;
                    case 'account-review':
                        this.set('status', STATUS_REVIEW);
                        break;
                    case 'already-active':
                        this.set('status', STATUS_OK);
                        break;
                    default:
                        this.trigger('error', err.error);
                        break;
                }
            });
    }
}


export default CreateAccountModel;

export {
    STATUS_OK,
    STATUS_REVIEW,
    STATUS_VERFIY,
};
