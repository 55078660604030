import fmt from '@lib/formatters';
import { BaseModel } from '@lib/models';

import { COUNTRY_CODE_CANADA, COUNTRY_CODE_USA } from '@lib/geo-country';

const REQUIRES_COUNTY = [COUNTRY_CODE_CANADA, COUNTRY_CODE_USA];


class AddressModel extends BaseModel {
    static defaults = {
        address1: '',
        address2: '',
        city: '',
        county: '',
        postcode: '',
        country: '',
        premises: '',
        thoroughfare: '',
        separateAddressFields: false,
        useAccountAddress: false,
    }

    validation = {
        address1: [
            {
                required: () => !this.get('useAccountAddress') && !this.get('separateAddressFields'),
                msg: 'Address is required',
            },
            {
                maxLength: 100,
                msg: 'Cannot exceed 100 characters',
            },
        ],
        address2: {
            maxLength: 100,
            msg: 'Cannot exceed 100 characters',
        },
        city: {
            required: () => !this.get('useAccountAddress'),
            maxLength: 40,
            msg: 'Cannot exceed 40 characters',
        },
        county: {
            required: () => !this.get('useAccountAddress') && REQUIRES_COUNTY.includes(this.get('country')),
            maxLength: 20,
            msg: 'Cannot exceed 20 characters',
        },
        postcode: {
            required: () => !this.get('useAccountAddress'),
            fn: (value, attr, address) => {
                const label = address.country === COUNTRY_CODE_USA
                    ? 'ZIP Code'
                    : 'Postcode';

                if (value.trim() === '') {
                    return `${label} is required`;
                } else if (value.length > 10) {
                    return 'Cannot exceed 10 characters';
                }
            },
        },
        country: {
            required: () => !this.get('useAccountAddress'),
        },
        premises: [
            {
                required: () => !this.get('useAccountAddress') && this.get('separateAddressFields'),
                msg: 'Building name/number is required',
            },
            {
                maxLength: 60,
                msg: 'Cannot exceed 60 characters',
            },
        ],
        thoroughfare: [
            {
                required: () => !this.get('useAccountAddress') && this.get('separateAddressFields'),
                msg: 'Street name is required',
            },
            {
                maxLength: 55,
                msg: 'Cannot exceed 55 characters',
            },
        ],
    }

    get parameters() {
        return {
            address1: this.get('address1'),
            address2: this.get('address2'),
            city: this.get('city'),
            county: this.get('county'),
            postcode: fmt.ukPostcode(this.get('postcode'), true),
            country: this.get('country'),
        };
    }

    parse(data) {
        return {
            address1: data.address1 || '',
            address2: data.address2 || '',
            city: data.city || '',
            county: data.county || '',
            postcode: data.postcode || '',
            country: data.country || '',
        };
    }

    resetDefault() {
        const { useAccountAddress, country, ...defaults } = AddressModel.defaults;
        this.set({ ...defaults });
    }
}


export default AddressModel;
