import React from 'react';
import { Alert, Form } from 'react-bootstrap';

import { useAccount, useSend, useStateContext } from '@lib/hooks';


function NumberPortingCheckbox() {
    const { model: accountModel, fixedAttrs: { buyNumbers, portNumbers } } = useAccount();
    const send = useSend();
    const context = useStateContext();
    const hasBuyNumbers = buyNumbers?.length > 0;
    const hasPortNumbers = portNumbers?.length > 0;
    const disabled = hasPortNumbers || (accountModel.isResidential && hasBuyNumbers);

    return (
        <Alert variant="info">
            <Form.Check
                id="portNumber"
                name="portNumber"
                className="fs-5"
                label="I already own a number I want to use"
                disabled={disabled}
                checked={context?.showPorting}
                onChange={() => send(context?.showPorting ? 'STOP-PORTING' : 'START-PORTING')}
            />
        </Alert>
    );
}


export default NumberPortingCheckbox;
