import { gtag, install } from 'ga-gtag';

const PROPERTY_ID = 'G-18LLQLBSZV';


class GoogleAnalytics {
    constructor() {
        install(PROPERTY_ID, {
            debug_mode: process.env.NODE_ENV === 'development',
            send_page_view: false,
        });
    }

    sendEvent(name, data) {
        gtag('event', name, { ...data, send_to: PROPERTY_ID });
    }

    sendPageView(path) {
        this.sendEvent('page_view', { page_path: path });
    }
}


export default new GoogleAnalytics();
