import api from '@lib/api';
import { BaseCollection } from '@lib/models';

import AreaCodeModel from './area-code-model';


class AreaCodeCollection extends BaseCollection {
    url = 'numbersGeo'
    model = AreaCodeModel

    read = (model, options) => {
        api.v0.get(this.url)
            .then(([status, data]) => this.loadWith(data));
    }

    loadWith(data) {
        this.set(data[this.url].map(this.model.parse), { silent: true });
        this.trigger('sync');
    }
}


export default AreaCodeCollection;
