import { useState } from 'react';

import useDidMount from './use-did-mount';
import useWillUnmount from './use-will-unmount';


export function useCheckViewport({ width }) {
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const onWindowResize = () => setViewportWidth(window.innerWidth);

    useDidMount(() => window.addEventListener('resize', onWindowResize));
    useWillUnmount(() => window.removeEventListener('resize', onWindowResize));

    return viewportWidth < width;
}


export default useCheckViewport;
