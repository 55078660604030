import React from 'react';
import { Button } from 'react-bootstrap';
import _ from 'underscore';

import { ButtonBar } from '@components';
import { FAILED_RESPONSES, STATUS_DECLINE } from '@components/onboarding-account';
import { useCreateAccount, useDidMount, useNotify } from '@lib/hooks';

const errorMsgs = {
    'delivery': "Delivery address is required. Please try again.",
    'hardware': "You have selected an invalid or the same extension for multiple phones.",
    'account-invalid': "We are missing some account details. Please try again.",
    'basket-invalid': "There was an error processing your basket. Please try again.",
};


function PaymentForm({ onFormLoaded }) {
    const { fixedAttrs, model } = useCreateAccount();
    const [PaymentAlert] = useNotify({ model, errorMsgs, withScroll: true });

    useDidMount(() => model.save());

    return (
        <React.Fragment>
            <PaymentAlert />

            {_.contains(FAILED_RESPONSES, fixedAttrs.status) &&
                <React.Fragment>
                    {fixedAttrs.status === STATUS_DECLINE &&
                        <p>Sorry, your transaction was declined.</p>
                    }

                    {fixedAttrs.status !== STATUS_DECLINE &&
                        <p>Sorry, there was a problem. Please try again.</p>
                    }

                    <ButtonBar>
                        <Button
                            variant="success"
                            onClick={() => model.save()}
                        >
                            Try Again
                        </Button>
                    </ButtonBar>
                </React.Fragment>
            }

            {fixedAttrs.url &&
                <iframe
                    title="Payment Form"
                    src={fixedAttrs.url}
                    className="d-block w-100"
                    style={{ minHeight: '480px' }}
                    onLoad={onFormLoaded}
                />
            }
        </React.Fragment>
    );
}


export default PaymentForm;
