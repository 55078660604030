import React, { useState } from 'react';
import { Button, Col, Modal, Row, Stack } from 'react-bootstrap';

import fmt from '@lib/formatters';
import { useAccount } from '@lib/hooks';


function ResidentialNumberPopup({ onConfirm, onCancel }) {
    const { fixedAttrs: { buyNumbers, portNumbers } } = useAccount();
    const geoNumbers = buyNumbers.filter(item => item.product.isGeoNumber);
    const businessNumbers = buyNumbers.filter(item => !geoNumbers.includes(item));
    const hasGeoNumbers = geoNumbers.length > 0;
    const hasPortNumbers = portNumbers.length > 0;
    const [selectedNumber, setSelectedNumber] = useState(geoNumbers[0]?.product ||
        (hasPortNumbers && portNumbers[0]) || null);

    return (
        <Modal show={true}>
            <Modal.Header>
                <Modal.Title>Review numbers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>We're sorry, but residential users can only have one UK local number.</p>

                {hasGeoNumbers &&
                    <p>Please select which one you wish to keep below, or choose "I'm a business
                    user" if you need multiple phone numbers.</p>
                }

                <Stack gap={1}>
                    {(hasGeoNumbers || hasPortNumbers) &&
                        <div>
                            {geoNumbers.map(item => (
                                <div key={item.product.uid}>
                                    <label>
                                        <input
                                            className="review-number"
                                            type="radio"
                                            value={item.product.number}
                                            checked={item.product.isEqual(selectedNumber)}
                                            onChange={() => setSelectedNumber(item.product)}
                                            style={{ marginRight: 5 }}
                                        />
                                        <span>{fmt.phoneNumber(item.product.number)}</span>
                                    </label>
                                </div>
                            ))}

                            {portNumbers.map((number, index) => (
                                <div key={index}>
                                    <label>
                                        <input
                                            className="review-number"
                                            type="radio"
                                            value={number}
                                            checked={number === selectedNumber}
                                            onChange={() => setSelectedNumber(number)}
                                            style={{ marginRight: 5 }}
                                        />
                                        <span>{fmt.phoneNumber(number)}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    }

                    {businessNumbers.length > 0 &&
                        <div>
                            {hasGeoNumbers && <hr />}
                            <p>The following number(s) are only available to business users:</p>
                            <ul>
                                {businessNumbers.map(item => (
                                    <li key={item.product.uid}>
                                        {fmt.phoneNumber(item.product.number)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }
                </Stack>

                <br />

                <Row>
                    <Col>
                        <Button variant="success" onClick={() => onConfirm(selectedNumber)}>
                            Continue
                        </Button>
                    </Col>
                    <Col className="text-end">
                        <Button variant="link" onClick={onCancel}>
                            I'm a business user
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}


export default ResidentialNumberPopup;
