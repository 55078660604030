import api from '@lib/api';
import { BaseCollection } from '@lib/models';

import NumberModel from './number-model';

const PREFIX_ONE_OFF = '0207';


class NumberCollection extends BaseCollection {
    _urlBase = 'numbers'
    model = NumberModel
    page = 0
    totalPages = 0

    url = () => {
        const area = this.context.type || this.context.area;
        const filter = this.context.type === PREFIX_ONE_OFF ? '' : this.context.filter;
        return `${this._urlBase}/${area}/${filter}/${this.page + 1}`;
    }

    reset = () => {
        super.reset();
        this.page = 0;
        this.totalPages = 0;
        this.trigger('pages');
    }

    read = (model, options) => {
        api.v0.get(this.url())
            .then(([status, data]) => this.loadWith(data));
    }

    loadWith(data) {
        const nums = data[this.url()];
        this.set(nums.numbers.map(item => this.model.parse(item, nums.sku)));

        if (this.page === 0) {
            this.totalPages = nums.pages;
            this.trigger('pages');
        }

        this.trigger('sync');
    }
}


export default NumberCollection;

export {
    PREFIX_ONE_OFF,
};
