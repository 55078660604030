import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { Cart, ChevronDown, ChevronRight, ChevronUp } from 'react-bootstrap-icons';

import { ButtonBar, ErrorList } from '@components';
import fmt from '@lib/formatters';
import { useBasket, useDidMount, useSend } from '@lib/hooks';

import Basket from './basket';
import PaymentForm from './payment-form';


const title = 'Payment';


function PaymentPage() {
    const [showBasket, setShowBasket] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(true);
    const [continueButtonEnabled, setContinueButtonEnabled] = useState(false);
    const [hasValidated, setHasValidated] = useState(false);
    const basket = useBasket();
    const send = useSend();

    useDidMount(() => basket.validate());

    useEffect(() => {
        if (basket.count === 0) {
            send('NAVIGATE-CONTACT');
        }
    }, [basket.count]);

    useEffect(() => {
        if (basket.isValidating) setHasValidated(true);
        setContinueButtonEnabled(hasValidated && !basket.isValidating);
    }, [basket.isValidating, hasValidated]);

    return (
        <div>
            <h2>Payment</h2>

            {basket.validationErrors.length > 0 &&
                <ErrorList errors={basket.validationErrors} />
            }

            <Card bg="light">
                <Card.Body>
                    <Row className="align-items-center g-2 g-sm-0">
                        <Col xs={12} sm={8}>
                            <Button
                                variant="link"
                                className="text-decoration-none fw-bold text-body"
                                onClick={() => setShowBasket(!showBasket)}
                            >
                                <Cart />
                                <span className="ms-2 me-2 align-middle">Show order summary</span>
                                {showBasket ? <ChevronUp /> : <ChevronDown />}
                            </Button>
                        </Col>
                        <Col className="text-sm-end">
                            {basket.isValidating ?
                                <Spinner
                                    variant="purple"
                                    animation="border"
                                    className="align-middle"
                                /> :
                                <React.Fragment>
                                    <span className="fs-5">
                                        &pound;{fmt.price(basket.totalIncVat())}
                                        &nbsp;
                                    </span>
                                    <small>
                                        (&pound;{fmt.price(basket.totalExVat())} ex. VAT)
                                    </small>
                                </React.Fragment>
                            }
                        </Col>
                    </Row>

                    {showBasket && <Basket />}
                </Card.Body>
            </Card>

            <br />

            {showContinueButton &&
                <ButtonBar>
                    <Button
                        variant="success"
                        disabled={!continueButtonEnabled}
                        onClick={() => {
                            setContinueButtonEnabled(false);
                            setShowPaymentForm(true);
                        }}
                    >
                        {basket.isFreePayment() ?
                            'Create Account' : 'Continue to Payment'} <ChevronRight />
                    </Button>
                </ButtonBar>
            }

            <br />

            {showPaymentForm &&
                <PaymentForm onFormLoaded={() => setShowContinueButton(false)} />
            }
        </div>
    );
}


export default PaymentPage;

export {
    title,
};
