import React from 'react';

import { COUNTRY_CODE_USA } from '@lib/geo-country';
import { useCollection } from '@lib/hooks';

import StateCollection from './state-collection';


function StateOptGroup({ stateCollection, country, loader }) {
    const { fixedList } = useCollection(stateCollection || StateCollection, { loader });

    return fixedList.length < 1 ? '' : (
        <optgroup
            label={country === COUNTRY_CODE_USA
                ? 'State'
                : 'Province'
            }
        >
            {fixedList.map(item => item.country === country && (
                <option
                    key={item.code}
                    value={item.code}
                >{item.name}</option>
            ))}
        </optgroup>
    );
}


export default StateOptGroup;
