import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Briefcase, House } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';

import { ButtonBar, NextButton, Notifications, ResidentialNumberPopup } from '@components';
import { CUSTOMER_TYPE_BUSINESS, CUSTOMER_TYPE_RESIDENTIAL } from '@components/onboarding-account';
import { useAccount, useSend } from '@lib/hooks';

const title = 'Introduction';


function IntroPage() {
    const params = useParams();
    const { model: accountModel, fixedAttrs: { plan: activePlan } } = useAccount();
    const send = useSend();
    const [showNumberPopup, setShowNumberPopup] = useState(false);

    const setCustomerType = (customerType) => {
        accountModel.set('customerType', customerType);
        send('NAVIGATE-NEXT');
    };

    const onCustomerTypeSelected = (customerType) => {
        const isResidential = customerType === CUSTOMER_TYPE_RESIDENTIAL;

        if (isResidential && !accountModel.isBasketResidentialCompatible) {
            setShowNumberPopup(true);
        } else {
            setCustomerType(customerType);
        }
    };

    const onNumberPopupConfirmed = (selectedNumber) => {
        const hasBusinessPlan = activePlan && !activePlan.product.isResidential;

        if (hasBusinessPlan) {
            accountModel.removeProduct(activePlan.product);
        }

        accountModel.setNumber(selectedNumber);
        setCustomerType(CUSTOMER_TYPE_RESIDENTIAL);
    };

    const onNumberPopupCancelled = () => {
        setCustomerType(CUSTOMER_TYPE_BUSINESS);
    };

    return (
        <div>
            <h2>Welcome to Voipfone</h2>

            <Notifications />

            <p className="fs-6 fw-bold">Your account number is {params.account}</p>

            <div className="text-center mb-3">
                <img src="/about.jpg" width="70%" alt="Welcome to Voipfone" />
            </div>

            <p>Congratulations, we've created your account and you're almost ready to
            start making calls - we just need to ask you for a few essential pieces of
            information before your account is ready to use.</p>

            {activePlan &&
                <ButtonBar>
                    <NextButton onClick={() => send('SKIP')} />
                </ButtonBar>
            }

            {!activePlan &&
                <>
                    <p>First of all, please select your account type:</p>
                    <Row className="text-center">
                        <Col sm className="p-2">
                            <Button variant="primary" onClick={() => onCustomerTypeSelected(CUSTOMER_TYPE_BUSINESS)} style={btnStyle}>
                                <Briefcase size={36} />
                                <br />
                                I'm a <span className="text-nowrap">business user</span>
                            </Button>
                        </Col>
                        <Col sm className="p-2">
                            <Button variant="success" onClick={() => onCustomerTypeSelected(CUSTOMER_TYPE_RESIDENTIAL)} style={btnStyle}>
                                <House size={36} />
                                <br />
                                I'm a <span className="text-nowrap">residential user</span>
                            </Button>
                        </Col>
                    </Row>
                </>
            }

            {showNumberPopup &&
                <ResidentialNumberPopup
                    onConfirm={onNumberPopupConfirmed}
                    onCancel={onNumberPopupCancelled}
                />
            }
        </div>
    );
}


const btnStyle = {
    minWidth: '90%',
};


export default IntroPage;

export {
    title,
};
