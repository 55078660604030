import { BaseModel } from '@lib/models';


class CountryModel extends BaseModel {
    static defaults = {
        id: -1,
        code2: '',
        code3: '',
        name: '',
    }

    static parse(data) {
        return {
            id: parseInt(data[2]),
            code2: String(data[0]),
            code3: String(data[3]),
            name: String(data[1]),
        };
    }
}


export default CountryModel;
