import React from 'react';
import { Pagination } from 'react-bootstrap';
import _ from 'underscore';

const MAX_PAGES = 5;
const MAX_PAGES_OFFSET = Math.floor(MAX_PAGES / 2);


function Pager({ page, totalPages, isFirst, isLast, firstPage, lastPage, goToPage }) {
    const startPages = Math.max(firstPage, page - MAX_PAGES_OFFSET);
    const endPages = Math.min(totalPages, startPages + MAX_PAGES);

    return (
        <React.Fragment>
            {lastPage > 0 &&
                <Pagination className="justify-content-center mt-4">
                    <Pagination.First
                        disabled={isFirst}
                        onClick={() => goToPage(firstPage)}
                    />

                    <Pagination.Prev
                        disabled={isFirst}
                        onClick={() => goToPage(page - 1)}
                    />

                    {startPages > firstPage &&
                        <Pagination.Ellipsis
                            onClick={() => goToPage(Math.max(firstPage, page - MAX_PAGES))}
                        />
                    }

                    {_.range(startPages, endPages).map(item => (
                        <Pagination.Item
                            key={item}
                            active={item === page}
                            onClick={() => goToPage(item)}
                        >{item + 1}</Pagination.Item>
                    ))}

                    {endPages < totalPages && (
                        <Pagination.Ellipsis
                            onClick={() => goToPage(Math.min(lastPage, page + MAX_PAGES))}
                        />
                    )}

                    <Pagination.Next
                        disabled={isLast}
                        onClick={() => goToPage(page + 1)}
                    />

                    <Pagination.Last
                        disabled={isLast}
                        onClick={() => goToPage(lastPage)}
                    />
                </Pagination>
            }
        </React.Fragment>
    );
}


export default Pager;
