import React from 'react';

import { useCollection } from '@lib/hooks';

import CountryCollection from './country-collection';


function CountryOptGroup({ loader, countryCollection }) {
    const { fixedList } = useCollection(countryCollection || CountryCollection, { loader });

    return fixedList.length < 1 ? '' : (
        <optgroup label="Countries">
            {fixedList.map(item => (
                <option
                    key={item.id}
                    value={item.code2}
                >{item.name}</option>
            ))}
        </optgroup>
    );
}


export default CountryOptGroup;
