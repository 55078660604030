import dayjs from 'dayjs';

import AddressModel from '@content/contact-page/address-model';
import { DEFAULT_COUNTRY } from '@lib/geo-country';
import { BaseModel } from '@lib/models';
import utils from '@lib/utils';


class BroadbandConfigurationModel extends BaseModel {
    static defaults = {
        firstname: '',
        surname: '',
        email: '',
        companyName: '',
        mobileNumber: '',
        currentIsp: '',
        address: new AddressModel(),
        package: 0,
        number: '',
        line: '',
        router: '',
        requestInstallationDate: false,
        requestedInstallationDate: dayjs(),
        downloadSpeed: 0,
        uploadSpeed: 0,
        staticIp: false,
    }

    validation = {
        requestedInstallationDate: {
            required: () => this.get('requestInstallationDate'),
            fn: val => {
                if (val.isBefore(this.minInstallationDate, 'day') ||
                    val.isAfter(this.maxInstallationDate, 'day')) {
                    return 'Invalid installation date';
                }
            },
        }
    }

    get parameters() {
        const { address, requestInstallationDate } = this.attributes;
        return {
            name: this.get('firstname'),
            surname: this.get('surname'),
            email: this.get('email'),
            company_name: this.get('companyName'),
            mobile_number: this.get('mobileNumber'),
            current_isp: this.get('currentIsp'),
            house_no: address.get('address1'),
            street: address.get('address2'),
            city: address.get('city'),
            postcode: address.get('postcode'),
            date_request: requestInstallationDate ? 'y' : 'n',
            requested_installation_date: requestInstallationDate ?
                this.get('requestedInstallationDate').format('DD/MM/YYYY') : null,
            package: this.get('package'),
            number: this.get('number'),
            line: this.get('line'),
            router: this.get('router'),
            download_speed: this.get('downloadSpeed'),
            upload_speed: this.get('uploadSpeed'),
            static_ip: this.get('staticIp') ? 'y' : 'n',
        };
    }

    get minInstallationDate() {
        return dayjs().add(7, 'day');
    }

    get maxInstallationDate() {
        return dayjs().add(60, 'day');
    }

    parse(data) {
        const requestedInstallationDate = data.requested_installation_date !== null
            ? utils.convertDate(data.requested_installation_date, 'DD/MM/YYYY')
            : this.minInstallationDate;

        return {
            firstname: String(data.name),
            surname: String(data.surname),
            email: String(data.email),
            companyName: String(data.company_name),
            mobileNumber: String(data.mobile_number),
            currentIsp: String(data.current_isp),
            address: new AddressModel({
                address1: String(data.house_no),
                address2: String(data.street),
                city: String(data.city),
                postcode: String(data.postcode),
                country: DEFAULT_COUNTRY,
            }),
            requestInstallationDate: data.date_request === 'y',
            requestedInstallationDate: requestedInstallationDate.isBefore(this.minInstallationDate, 'day')
                ? this.minInstallationDate
                : requestedInstallationDate,
            package: parseInt(data.package),
            number: String(data.number),
            line: String(data.line),
            router: data.router || '',
            downloadSpeed: parseInt(data.download_speed),
            uploadSpeed: parseInt(data.upload_speed),
            staticIp: data.static_ip === 'y',
        };
    }

    read(model, options) {
        this.set(this.parse(options));
    }
}


export default BroadbandConfigurationModel;
