import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { ButtonBar, NextButton, Notifications } from '@components';
import { NotificationAlert } from '@components/notifications';
import { useAccount, useSend } from '@lib/hooks';

import HardwareItems from './hardware-items';

import './hardware-page.scss';

const title = 'Hardware';


function HardwarePage() {
    const send = useSend();
    const { fixedAttrs: { hardware }, model: account } = useAccount();

    const onPlanBtnClicked = () => {
        account.toggleCustomerType();
        send('NAVIGATE-PLAN');
    };

    useEffect(() => {
        if (hardware.length === 0) {
            send('NAVIGATE-CONTACT');
        }
    }, [hardware]);

    return (
        <div>
            <h2>Hardware</h2>

            <Notifications />

            {account.isBusiness &&
                <>
                    <p>To make your life easier, we can pre-configure your phones with
                    your new Voipfone account details and UK phone settings before we
                    send them to you (note: we will need to open the phone packaging to
                    set them up).</p>

                    <p>If you've added extensions or plans to your shopping basket,
                    we've created a default configuration for your phones. If you want
                    to change or remove these defaults, alter the configuration below
                    to suit your requirements.</p>
                </>
            }

            {account.needsHardwareWarning &&
                <NotificationAlert variant="danger" className="mt-3">
                    <strong>Please note:</strong> it appears that you're purchasing
                    multiple phones, however <strong>only one phone</strong> can be
                    configured to your account. If you wish to use multiple phones,
                    please purchase a <Button variant="link" className="plan-link"
                    onClick={onPlanBtnClicked}>business plan</Button>.
                </NotificationAlert>
            }

            <HardwareItems />

            <ButtonBar>
                <NextButton onClick={() => send('NAVIGATE-CONTACT')} />
            </ButtonBar>
        </div>
    );
}


export default HardwarePage;

export {
    title,
};
