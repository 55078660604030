import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';


function Dialog({ title, message, onExit }) {
    const [show, setShow] = useState(true);

    return (
        <Modal show={show} onExited={onExit} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default Dialog;
