import { useEffect, useState } from 'react';

import useDidMount from './use-did-mount';
import useWillUnmount from './use-will-unmount';


function usePagination({ items, collection, pageSize=50 }) {
    const _page = collection?.page || 0;
    const _totalPages = collection ? collection?.totalPages : Math.ceil(items.length / pageSize);
    const [page, setPage] = useState(_page);
    const [totalPages, setTotalPages] = useState(_totalPages);
    const lastPage = totalPages - 1;
    const start = page * pageSize;

    useDidMount(() => {
        collection?.on('pages', pages => {
            setPage(collection.page);
            setTotalPages(collection.totalPages);
        });
    });

    useEffect(() => {
        if (collection && totalPages > 0 && collection.page !== page) {
            collection.page = page;
            collection.fetch();
        }
    }, [collection, totalPages, page]);

    useWillUnmount(() => {
        collection?.off('pages');
    });

    return {
        items: items?.slice(start, start + pageSize),
        isFirst: page <= 0,
        isLast: page >= lastPage,
        firstPage: 0,
        lastPage,
        pageSize,
        totalPages,
        page,
        goToPage: (num) => {
            const newPage = parseInt(num);
            if (!isNaN(newPage) && newPage >= 0 && newPage <= lastPage) {
                setPage(newPage);
            }
        },
    };
}


export default usePagination;
