import { useContext } from 'react';

import { AppContext } from '@lib/store';


function useStateContext() {
    const { context } = useContext(AppContext);
    return context;
}


export default useStateContext;
