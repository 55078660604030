import React from 'react';
import { ListGroup } from 'react-bootstrap';


function ErrorList({ errors }) {
    return (
        <ListGroup className="mt-2 mb-2">
            {errors.map((error, index) => (
                <ListGroup.Item key={index} variant="danger">
                    {error}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}


export default ErrorList;
