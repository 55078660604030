import api from '@lib/api';
import { BaseCollection } from '@lib/models';

import BroadbandRouterModel from './broadband-router-model';


class BroadbandRouterCollection extends BaseCollection {
    url = 'broadband/routers'
    model = BroadbandRouterModel

    read = (model, options) => {
        api.v1.get(this.url, 'json')
            .then(data => this.loadWith(data));
    }

    loadWith(data) {
        this.set(data.routers.map(this.model.parse), { silent: true });
        this.trigger('sync');
    }
}


export default BroadbandRouterCollection;
