import React from 'react';
import { Button } from 'react-bootstrap';
import _ from 'underscore';


function SaveButton({ canSave, label='Save Changes' }) {
    const _canSave = (_.isBoolean(canSave) && canSave)
        || (_.isFunction(canSave) && canSave());

    return (
        <Button
            type="submit"
            variant="primary"
            disabled={!_canSave}
        >{label}</Button>
    );
}


export default SaveButton;
