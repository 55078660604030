import React, { useRef, useState } from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import _ from 'underscore';

import { useDidMount } from '@lib/hooks';
import { formChangeHandler } from '@lib/utils';

import './bound-form-control.scss';


function BoundFormControl({ bind, dataType, field, autofocus=false, type='text',
		onChange=formChangeHandler, ...props }) {
	const [isFocused, setIsFocused] = useState(false);
	const fieldElement = useRef();

	useDidMount(() => {
		autofocus && fieldElement.current && fieldElement.current.focus();
	});

	const onFieldFocussed = () => setIsFocused(true);
	const onFieldBlurred = () => setIsFocused(false);

	const errors = bind && bind.model.validate();
	const myError = _.has(errors, field) && errors[field];
	let FormElement = Form.Control;

	if (type === 'switch') {
		FormElement = Form.Switch;
        props.checked = bind && bind[field];
	} else if (type === 'checkbox') {
		FormElement = Form.Check;
		props.checked = bind && bind[field];
	}

	return (
		<OverlayTrigger
			show={isFocused && _.isString(myError)}
			overlay={<Popover><Popover.Body>{myError}</Popover.Body></Popover>}
		>
			<FormElement
				ref={fieldElement}
				name={field}
				type={type}
				className={myError && 'error'}
				value={bind && bind[field]}
				onFocus={onFieldFocussed}
				onBlur={onFieldBlurred}
				onChange={onChange(bind.model, field, dataType)}
				{...props}
				bind={bind}
				field={field}
			/>
		</OverlayTrigger>
	);
}


export default BoundFormControl;
