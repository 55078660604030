import { Model } from 'backbone';


class BasketProduct extends Model {
    static defaults = {
        product: null,
        quantity: 0,
        configuration: null,
    }

    get product() {
        return this.get('product');
    }

    get quantity() {
        return this.get('quantity');
    }

    get configuration() {
        return this.get('configuration');
    }

    set quantity(quantity) {
        this.set('quantity', quantity <= this.product.maxQuantity ? quantity : this.quantity);
    }
}


export {
    BasketProduct,
};
