import { useContext } from 'react';
import _ from 'underscore';

import { AppContext } from '@lib/store';
import { useAccount, useCollection } from '@lib/hooks';


/** Use the prices list/collection from context
 *
 * Essentially a wrapper around a couple of other hooks to avoid adding boilerplate to content implementations.
 *
 * @return Object See useCollection() hook
 */
function usePriceList() {
    const { priceCollection } = useContext(AppContext);
    const { collection } = useCollection(priceCollection, { fetch: false });
    const products = collection.getProducts();
    const plans = products.filter(item => item.type === 'plan');
    const { fixedAttrs: { technicalPartnerCode } } = useAccount();

    const getPromoProductOf = (product) => {
        return technicalPartnerCode && plans.find(plan => !plan.isTrialPlan &&
            plan.renewalSku === product.sku && plan.partnerCode === technicalPartnerCode);
    };

    return {
        products,
        getProduct: collection.getProduct,
        plans: {
            all: plans,
            paid: plans.filter(plan => !plan.isTrialPlan && !plan.partnerCode).map(plan => getPromoProductOf(plan) || plan),
            trial: plans.filter(plan => plan.isTrialPlan),
        },
        getTrialProductOf: (product) => _.findWhere(plans, { isTrialPlan: true, renewalSku: product.sku }),
        getPromoProductOf,
    };
}


export default usePriceList;
