import React from 'react';
import { Button, Modal } from 'react-bootstrap';


function PortingConfirmationDialog({ onConfirmed, ...props }) {
    return (
        <Modal {...props}>
            <Modal.Header>
                <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p><strong>Please note:</strong> to complete the number porting
                process you will need to enter some additional information from
                the Voipfone Control Panel once you've finished setting up
                your account.</p>
                <p>Please check your inbox for further instructions.</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={onConfirmed}>
                    I understand
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


export default PortingConfirmationDialog;
