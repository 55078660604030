import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { useBasket } from '@lib/hooks';

import BasketEditButton from './basket-edit-button';
import PriceLabel from './price-label';


function BasketRow({ children, ...props }) {
    const [left, right] = React.Children.toArray(children);

    return (
        <React.Fragment>
            <Col xs={4} sm={3} md={3} lg={2} {...props}>
                {left}
            </Col>
            <Col xs={8} sm={9} md={9} lg={10} {...props}>
                {right}
            </Col>
        </React.Fragment>
    );
}


function BasketMobile({ items }) {
    const basket = useBasket();

    return (
        <React.Fragment>
            {items.map(({ product, quantity }) => (
                <React.Fragment key={product.uid}>
                    <Row className="small gy-1 mt-2">
                        <Col xs={10}>{quantity} x {product.name}</Col>
                        <Col xs={2} className="text-end">
                            <BasketEditButton product={product} />
                        </Col>
                        <BasketRow>
                            <strong>One-off:</strong>
                            <PriceLabel price={product.oneOffPrice * quantity} />
                        </BasketRow>
                        <BasketRow>
                            <strong>Monthly:</strong>
                            <PriceLabel price={product.renewalPrice * quantity} />
                        </BasketRow>
                        <BasketRow>
                            <strong>Pay today:</strong>
                            <PriceLabel price={product.payTodayPrice * quantity} />
                        </BasketRow>
                    </Row>

                    <hr className="bg-secondary mt-2 mb-2" />
                </React.Fragment>
            ))}

            <Row className="gy-1">
                <BasketRow className="small">
                    Sub-total
                    <PriceLabel price={basket.totalExVat()} />
                </BasketRow>
                <BasketRow className="small">
                    VAT
                    <PriceLabel price={basket.totalVat()} />
                </BasketRow>
                <BasketRow className="small">
                    Pay monthly
                    <PriceLabel price={basket.totalRenewalPriceIncVat()} />
                </BasketRow>
                <BasketRow className="fw-bold">
                    Pay today
                    <PriceLabel price={basket.totalIncVat()} />
                </BasketRow>
            </Row>
        </React.Fragment>
    );
}


export default BasketMobile;
