import React from 'react';
import { Button } from 'react-bootstrap';


function AddButton({ onClick, label='Add' }) {
    return (
        <Button
            type="button"
            variant="primary"
            onClick={onClick}
        >{label}</Button>
    );
}


export default AddButton;
