import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import _ from 'underscore';

import { BoundFormControl } from '@components/forms';
import address from '@lib/address';
import { useCollection, useModel } from '@lib/hooks';

const PREMISES_FIELDS = [
    'building_number',
    'building_name',
    'sub_building_number',
    'sub_building_name',
];


function PostcodeSearch({ addressModel, countryCollection, onAddressSelected, onError }) {
    const { fixedAttrs, model } = useModel(addressModel);
    const { fixedList } = useCollection(countryCollection);
    const [results, setResults] = useState([]);

    const onFormSubmit = (e) => {
        e.preventDefault();
        address.fromPostcode(fixedAttrs.postcode)
            .then(data => {
                sortAddresses(data.addresses);
                setResults(data.addresses);
            })
            .catch(onError);
    };

    const onAddressChanged = (e) => {
        const address = results[e.target.value];
        const premisesValues = PREMISES_FIELDS.map(field => address[field]);
        let country = _.findWhere(fixedList, { name: address.country, });
        model.set({
            address1: address['line_1'],
            address2: address['line_2'],
            city: address['town_or_city'],
            county: address['county'],
            premises: premisesValues.find(item => item !== '') || '',
            thoroughfare: address['thoroughfare'],
        });

        // Channel island postcode searches may return the
        // island name in the "town_or_city" field
        if (!country) {
            country = _.findWhere(fixedList, { name: address.town_or_city, });
        }

        if (country) {
            model.set('country', country.code2);
        }

        onAddressSelected();
    };

    const sortAddresses = (addresses) => {
        addresses.sort((a, b) => {
            return a.line_1.localeCompare(b.line_1, 'en', {
                numeric: true,
            });
        });
    };

    return (
        <React.Fragment>
            <Form onSubmit={onFormSubmit}>
                <Row className="g-2">
                    <Col>
                        <BoundFormControl
                            bind={fixedAttrs}
                            field="postcode"
                            placeholder="Postcode"
                        />
                    </Col>
                    <Col xs={12} lg="auto">
                        <Button
                            variant="success"
                            type="submit"
                            disabled={fixedAttrs.postcode.length === 0}
                        >Find My Address</Button>
                    </Col>
                </Row>
            </Form>

            {results.length > 0 &&
                <p className="mt-2">
                    <Form.Select htmlSize={8} onChange={onAddressChanged}>
                        {results.map((item, index) =>
                            <option key={index} value={index}>
                                {_.compact(item.formatted_address).join(', ')}
                            </option>
                        )}
                    </Form.Select>
                </p>
            }
        </React.Fragment>
    );
}


export default PostcodeSearch;
