import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, Row, Spinner } from 'react-bootstrap';

import { Picker } from '@components/forms';
import { useAccount, useCollection, useModel } from '@lib/hooks';

import AreaCodeSelect from './area-code-select';
import NumberFilterModel from './number-filter-model';
import NumberCollection, { PREFIX_ONE_OFF } from './number-collection';
import NumberResults from './number-results';
import NumberTypeOptGroup from './number-type-opt-group';


function NumberSearch() {
    const [isLoading, setIsLoading] = useState(false);
    const { model: accountModel } = useAccount();
    const { fixedAttrs, model } = useModel(NumberFilterModel);
    const { collection: numberCollection } = useCollection(NumberCollection, {
        fetch: false,
    });

    const onAreaCodeChanged = ({ value }) => {
        model.set('area', value);
    };

    useEffect(() => {
        if (model.isValid()) {
            setIsLoading(true);
            numberCollection.context = { ...fixedAttrs };
            numberCollection.reset();
            numberCollection.once('sync', () => setIsLoading(false));
            numberCollection.fetch();
        }
    }, [fixedAttrs, model, numberCollection]);

    return (
        <React.Fragment>
            <Alert variant="secondary" as={Row}>
                <Row>
                    {accountModel.isBusiness &&
                        <Col xs={12} xl={4}>
                            <Form.Group>
                                <Form.Label className="lh-1 fw-bold text-body">Number Type</Form.Label>
                                <Picker className="mb-2" bind={fixedAttrs} field="type">
                                    <NumberTypeOptGroup />
                                </Picker>
                            </Form.Group>
                        </Col>
                    }
                    {!fixedAttrs.type &&
                        <Col xs={12} xl={4}>
                            <Form.Group>
                                <Form.Label className="lh-1 fw-bold text-body">Area Code</Form.Label>
                                <AreaCodeSelect onChange={onAreaCodeChanged} />
                            </Form.Group>
                        </Col>
                    }
                    <Col xs={12} xl={4}>
                        {fixedAttrs.type !== PREFIX_ONE_OFF &&
                            <Form.Group>
                                <Form.Label className="lh-1 fw-bold text-body">Filter</Form.Label>
                                <Picker className="mb-2" bind={fixedAttrs} field="filter">
                                    <option value="">Standard &amp; Memorable</option>
                                    <option value="standard">Standard Only</option>
                                    <option value="memorable">Memorable Only</option>
                                </Picker>
                            </Form.Group>
                        }
                    </Col>
                </Row>
            </Alert>

            {isLoading && <div className="m-4 text-center"><Spinner animation="border" /></div>}
            {!isLoading && <NumberResults numberCollection={numberCollection} />}
        </React.Fragment>
    );
}


export default NumberSearch;
