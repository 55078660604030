import { BaseModel } from '@lib/models';


class NumberModel extends BaseModel {
    static defaults = {
        number: '',
        type: '',
        sku: '',
    }

    static parse(data, sku) {
        return {
            number: String(data.number),
            type: String(data.type),
            sku: String(sku),
        };
    }
}


export default NumberModel;
