import React from 'react';
import { Spinner } from 'react-bootstrap';


function SimpleWin({ withSpinner=false, children }) {
    return (
        <div className="bg-white mx-auto my-3 p-3 rounded-3 text-center loader-box">
            {withSpinner && <Spinner animation="border" />}
            <br />
            {children}
        </div>
    );
}


export default SimpleWin;
