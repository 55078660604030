import React, { useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import _ from 'underscore';

import { useAccount, useSend } from '@lib/hooks';
import fmt from '@lib/formatters';
import validators from '@lib/validators';
import { NextButton } from '@components';

import PortingConfirmationDialog from './porting-confirmation-dialog';

const BLOCKED_PREFIXES = ['07', '09'];


function PortingForm() {
    const send = useSend();
    const { model: accountModel, fixedAttrs: { portNumbers } } = useAccount();
    const [portNumber, setPortNumber] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const hasPortNumber = portNumbers.length > 0;

    const isValid = (number) => {
        const blockedPrefixes = [...BLOCKED_PREFIXES];

        if (accountModel.isResidential) {
            blockedPrefixes.push('03');
        }

        return _.isUndefined(validators.phoneNumberByCountry(number, 'GB')) &&
            !_.contains(portNumbers, fmt.phoneNumber(number)) &&
            !_.contains(blockedPrefixes, fmt.phoneNumber(portNumber).substr(0, 2));
    };

    const onFormSubmitted = e => {
        e.preventDefault();

        if (accountModel.isResidential && hasPortNumber) {
            setShowConfirmation(true);
        } else if (isValid(portNumber)) {
            const formatted = fmt.phoneNumber(portNumber);
            accountModel.set('portNumbers', [formatted, ...portNumbers]);
            setPortNumber('');

            if (accountModel.isResidential) {
                setShowConfirmation(true);
            }
        }
    };

    return (
        <Alert variant="info">
            <Form onSubmit={onFormSubmitted}>
                <Row>
                    <Col className="mb-3 mb-sm-0">
                        <Form.Control
                            name="numberToPort"
                            type="text"
                            placeholder="Number to Port"
                            value={portNumber}
                            onChange={({ target }) => setPortNumber(target.value)}
                            disabled={accountModel.isResidential && hasPortNumber}
                        />
                    </Col>
                    <Col sm={4} lg={3}>
                        {accountModel.isBusiness &&
                            <Button
                                variant="success"
                                className="w-100"
                                type="submit"
                                disabled={!isValid(portNumber)}
                            >Add Number</Button>
                        }

                        {accountModel.isResidential &&
                            <NextButton
                                variant="success"
                                className="w-100"
                                type="submit"
                                disabled={!isValid(portNumber) && !hasPortNumber}
                            />
                        }
                    </Col>
                </Row>
            </Form>

            <PortingConfirmationDialog
                show={showConfirmation}
                backdrop="static"
                onConfirmed={() => send('NAVIGATE-NEXT')}
            />
        </Alert>
    );
}


export default PortingForm;
