import React from 'react';
import { CheckLg as Check, XLg as X } from 'react-bootstrap-icons';
import _ from 'underscore';

import fmt from '@lib/formatters';
import { SERVICES_WITH_MULTIPLE } from '@lib/services';


function PlanInclusiveService({ product, inclusiveServices }) {
    const isInclusive = inclusiveServices && inclusiveServices[product.sku];
    const hasMultiple = _.contains(SERVICES_WITH_MULTIPLE, product.sku);

    return (
        <React.Fragment>
            {!inclusiveServices && product &&
                <span>
                    &pound;{fmt.price(product.price, true)}
                    {hasMultiple && ' each'}
                </span>
            }

            {inclusiveServices && isInclusive &&
                <Check className="text-success" />
            }

            {inclusiveServices && !isInclusive &&
                <X className="text-pink" />
            }
        </React.Fragment>
    );
}


export default PlanInclusiveService;
