import React, { useEffect } from 'react';
import _ from 'underscore';

import { BoundFormControl, Picker } from '@components/forms';
import { COUNTRY_CODE_USA } from '@lib/geo-country';
import { useCollection, useModel } from '@lib/hooks';

import StateOptGroup from './state-opt-group';
import StateCollection from './state-collection';


function ManualAddressForm({ addressModel }) {
    const { fixedAttrs, savedAttrs, model } = useModel(addressModel);
    const { fixedList, collection } = useCollection(StateCollection);
    const showStatePicker = _.contains(_.pluck(fixedList, 'country'), fixedAttrs.country);

    useEffect(() => {
        if (fixedAttrs.country !== savedAttrs.country) {
            model.set('county', '');
        }
    }, [showStatePicker]);

    return (
        <React.Fragment>
            {!fixedAttrs.separateAddressFields &&
                <p>
                    <BoundFormControl
                        bind={fixedAttrs}
                        field="address1"
                        placeholder="Address 1"
                    />
                </p>
            }

            {fixedAttrs.separateAddressFields &&
                <>
                    <p>
                        <BoundFormControl
                            bind={fixedAttrs}
                            field="premises"
                            placeholder="Building name/number"
                        />
                    </p>

                    <p>
                        <BoundFormControl
                            bind={fixedAttrs}
                            field="thoroughfare"
                            placeholder="Street name"
                        />
                    </p>
                </>
            }

            <p>
                <BoundFormControl
                    bind={fixedAttrs}
                    field="address2"
                    placeholder="Address 2"
                />
            </p>

            <p>
                <BoundFormControl
                    bind={fixedAttrs}
                    field="city"
                    placeholder="City"
                />
            </p>

            {!showStatePicker &&
                <p>
                    <BoundFormControl
                        bind={fixedAttrs}
                        field="county"
                        placeholder="County"
                    />
                </p>
            }

            {showStatePicker &&
                <div className="mb-3">
                    <Picker bind={fixedAttrs} field="county">
                        <Picker.PleaseSelectOption />
                        <StateOptGroup
                            stateCollection={collection}
                            country={fixedAttrs.country}
                        />
                    </Picker>
                </div>
            }

            <p>
                <BoundFormControl
                    bind={fixedAttrs}
                    field="postcode"
                    placeholder={fixedAttrs.country === COUNTRY_CODE_USA
                        ? 'ZIP Code'
                        : 'Postcode'
                    }
                />
            </p>
        </React.Fragment>
    );
}


export default ManualAddressForm;
