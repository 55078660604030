import { ID_MIXED_MINUTES } from '@lib/services';


export function getBgColour(activePlan, plan) {
    if (plan.sku === activePlan?.sku || plan.sku === activePlan?.renewalSku) {
        return 'yellow';
    } else if (plan.isResidential) {
        return 'lavender';
    } else {
        return 'blue';
    }
};


export function getContractLabel(plan) {
    return !plan.partnerCode && plan.contractLength > 1 ? `${plan.contractLength} Months` : 'No Contract';
};


export function getIncludedNumbersLabel(inclusiveServices) {
    return inclusiveServices ? 1 : 'None';
};


export function getMinutesLabel(inclusiveServices) {
    const minutes = inclusiveServices && inclusiveServices[ID_MIXED_MINUTES];
    const minutesLabel = minutes === -1 ? 'Unlimited' : `${minutes} Minutes`;
    return minutes ? minutesLabel : 'None';
};


export function getPercentOff(plan, comparisonPlan) {
    return comparisonPlan ? (plan.price / comparisonPlan.price) * 100 : 0;
};


export default {
    getBgColour,
    getContractLabel,
    getIncludedNumbersLabel,
    getMinutesLabel,
    getPercentOff,
};
