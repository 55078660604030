import { BLOCKED_COUNTRIES } from '@content/contact-page/country-collection';


const SERVICE_URL = 'https://ipwho.is/';

export const DEFAULT_COUNTRY = 'GB';
export const COUNTRY_CODE_USA = 'US';
export const COUNTRY_CODE_CANADA = 'CA';

export async function getGeoCountry() {
    return new Promise((resolve, reject) => {
        fetch(SERVICE_URL)
            .then((response) => response.json())
            .then((data) => {
                const countryCode = BLOCKED_COUNTRIES.includes(data.country_code)
                    ? DEFAULT_COUNTRY
                    : data.country_code;

                return resolve(countryCode);
            })
            .catch(() => resolve(DEFAULT_COUNTRY));
    });
}
