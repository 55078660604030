import { patterns } from 'backbone-validation';

import { BaseModel } from '@lib/models';
import fmt from '@lib/formatters';
import validators from '@lib/validators';


class AdminDetailsModel extends BaseModel {
    static defaults = {
        firstname: '',
        surname: '',
        email: '',
        phone: '',
        accountEmail: '',
    }

    validation = {
        firstname: [
            {
                required: true,
                msg: 'First name is required',
            },
            {
                maxLength: 20,
                msg: 'First name must be at most 20 characters',
            },
        ],
        surname: {
            required: true,
            maxLength: 20,
        },
        email: {
            required: true,
            fn: (val) => {
                if (!val.match(patterns.email)) {
                    return 'A valid email is required';
                } else if (val === this.get('accountEmail')) {
                    return 'Email cannot be same as main account';
                }
            },
        },
        phone: {
            required: false,
            fn: validators.phoneNumber,
        },
    }

    get parameters() {
        return {
            firstname: this.get('firstname'),
            surname: this.get('surname'),
            email: this.get('email'),
            phone: fmt.phoneNumberE164(this.get('phone')),
        };
    }

    parse(data) {
        return {
            firstname: data.firstname || '',
            surname: data.surname || '',
            email: data.email || '',
            phone: data.phone || '',
        };
    }
}


export default AdminDetailsModel;
