import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import constants from '@lib/constants';
import fmt from '@lib/formatters';
import { useAccount, usePriceList } from '@lib/hooks';
import { SERVICES_BUSINESS as services } from '@lib/services';

import planFmt from './plan-formatters';
import PlanInclusiveService from './plan-inclusive-service';
import PlanSelectorForm from './plan-selector-form';


function PlanInclusiveFeatures({ plan, bgColour }) {
    return (
        <React.Fragment>
            <div className={`p-2 bg-plan-${bgColour}-light`}>
                <Row>
                    <Col>
                        Included UK Calls
                        <br />
                        (per user)
                    </Col>
                    <Col xs={5} className="text-end text-nowrap">
                        {planFmt.getMinutesLabel(plan.inclusiveServices)}
                    </Col>
                </Row>
            </div>

            <div className={`p-2 bg-plan-${bgColour}-lighter`}>
                <Row>
                    <Col>
                        Included Numbers
                        <br />
                        (per account)
                    </Col>
                    <Col xs={5} className="text-end">
                        {planFmt.getIncludedNumbersLabel(plan.inclusiveServices)}
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}


function PlansMobile({ plans }) {
    const { getProduct, getTrialProductOf } = usePriceList();
    const { fixedAttrs: { plan: activePlan } } = useAccount();

    return (
        <Row xs={1} lg={2} className="g-4 justify-content-center">
            {plans.map(plan => {
                const bgColour = planFmt.getBgColour(activePlan?.product, plan);

                return (
                    <Col key={plan.uid} className="mt-4 mb-4">
                        <Card className={`h-100 border border-${bgColour}-lighter`}>
                            <Card.Header className={`text-center rounded-top ${plan.partnerCode ? 'offer' : ''} bg-plan-${bgColour}`}>
                                <h3 className="mt-3 mb-3">Voipfone {plan.shortName}</h3>
                                {plan.partnerCode &&
                                    <p className="small fw-bold">
                                        {plan.partnerCode.toUpperCase()} offer
                                        - {planFmt.getPercentOff(plan, getProduct(plan.renewalSku))}% off
                                        for first {plan.contractLength} months
                                    </p>
                                }
                                <p className="mt-3 mb-3 h1">&pound;{fmt.price(plan.partnerCode ? plan.price : plan.renewalPrice, true)}</p>
                                <p className="mt-3 mb-3 fw-bold">Per User Per Month</p>
                            </Card.Header>

                            <PlanInclusiveFeatures plan={plan} bgColour={bgColour} />

                            {services.map((service, index) => (
                                <div
                                    key={service.id}
                                    className={`p-2 border-bottom bg-plan-${bgColour}-lightest ${index + 1 < services.length ? 'border-light' : ''}`}
                                >
                                    <Row>
                                        <Col>
                                            <a className="text-body fw-bold"
                                                href={`${constants.BASE_VF_URL}/${service.url}`}
                                                target="_blank" rel="noopener noreferrer">
                                                {service.name}
                                            </a>
                                        </Col>
                                        <Col xs={plan.inclusiveServices ? 2 : 4} className="text-end">
                                            <PlanInclusiveService
                                                product={getProduct(service.id)}
                                                inclusiveServices={plan.inclusiveServices}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))}

                            <Card.Footer className="h-100 pb-3">
                                <Card.Text className="text-center">
                                    {planFmt.getContractLabel(plan)}
                                </Card.Text>

                                <PlanSelectorForm
                                    plan={plan}
                                    trialPlan={getTrialProductOf(plan)}
                                />
                            </Card.Footer>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
}


export default PlansMobile;
