import React from 'react';
import { Button, Modal } from 'react-bootstrap';


function ConfirmDialog({ message, onOkClicked, onCancelClicked, onExit, title='Confirm' }) {
    const onOkBtnClicked = () => {
        onOkClicked && onOkClicked();
        onExit && onExit();
    };

    const onCancelBtnClicked = () => {
        onCancelClicked && onCancelClicked();
        onExit && onExit();
    };

    return (
        <Modal show={true} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancelBtnClicked}>Cancel</Button>
                <Button onClick={onOkBtnClicked}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default ConfirmDialog;
