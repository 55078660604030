import { useContext, useState } from 'react';

import { AppContext } from '@lib/store';
import { useDidMount, useModel, useWillUnmount } from '@lib/hooks';


function useBasket() {
    const [validationErrors, setValidationErrors] = useState([]);
    const [isValidating, setIsValidating] = useState(false);
    const { accountModel } = useContext(AppContext);
    const account = useModel(accountModel);

    useDidMount(() => {
        account.model.on('validated:basket', errors => {
            setIsValidating(false);
            setValidationErrors(errors);
        });
    });

    useWillUnmount(() => {
        account.model.off('validated:basket');
    });

    return {
        items: account.model.getBasketItems().filter(item => item.quantity > 0),
        count: account.model.basketCount,
        isValidating,
        validationErrors,
        isFreePayment: () => account.model.totalExVat === 0 && account.model.basketCount > 0,
        getRelated: (product) => account.model.getRelatedProducts(product),
        totalExVat: () => account.model.totalExVat,
        totalIncVat: () => account.model.totalIncVat,
        totalVat: () => account.model.totalIncVat - account.model.totalExVat,
        totalOneOffExVat: () => account.model.totalOneOffExVat,
        totalOneOffIncVat: () => account.model.totalOneOffIncVat,
        totalOneOffVat: () => account.model.totalOneOffIncVat - account.model.totalOneOffExVat,
        totalRenewalPriceExVat: () => account.model.totalRenewalPriceExVat,
        totalRenewalPriceIncVat: () => account.model.totalRenewalPriceIncVat,
        totalRenewalPriceVat: () => account.model.totalRenewalPriceIncVat - account.model.totalRenewalPriceExVat,
        validate: () => {
            setIsValidating(true);
            account.model.trigger('validate:basket');
        },
        add: (product, quantity) => account.model.addProduct(product, quantity),
        remove: (product) => account.model.removeProduct(product),
        contains: (product) => account.model.contains(product),
        toggle: (product, quantity) => {
            if (account.model.contains(product)) {
                account.model.removeProduct(product);
            } else {
                account.model.addProduct(product, quantity);
            }
        },
    };
}


export default useBasket;
