import api from '@lib/api';
import { BaseCollection } from '@lib/models';

import BroadbandPackageModel from './broadband-package-model';


class BroadbandPackageCollection extends BaseCollection {
    url = 'broadband/packages'
    model = BroadbandPackageModel

    read = (model, options) => {
        api.v1.get(this.url, 'json')
            .then(data => this.loadWith(data));
    }

    loadWith(data) {
        this.set(data.packages.map(this.model.parse), { silent: true });
        this.trigger('sync');
    }
}


export default BroadbandPackageCollection;
