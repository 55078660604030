import { BaseModel } from '@lib/models';


class AreaCodeModel extends BaseModel {
    static defaults = {
        prefix: '',
        destination: '',
    }

    static parse(data) {
        return {
            prefix: String(data[0]),
            destination: String(data[2]),
        };
    }
}


export default AreaCodeModel;
