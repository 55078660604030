import React from 'react';

import { useBasket } from '@lib/hooks';


function BroadbandSetupCosts({ basketModel }) {
    const basket = useBasket();
    const relatedBasketItems = basket.getRelated(basketModel.product);

    relatedBasketItems.sort(({ product: a }, { product: b }) => a.price - b.price);

    return (
        <React.Fragment>
            <p className="fw-bold mb-2">Setup Costs</p>
            <ul>
                {relatedBasketItems.map(item => (
                    <li key={item.product.uid}>
                        {item.product.name} - <strong>&pound;{item.product.price}</strong>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    );
}


export default BroadbandSetupCosts;
