import Backbone from 'backbone';
import dayjs from 'dayjs';
import _ from 'underscore';
import { mixin as validation } from 'backbone-validation';

const GRACE_PERIOD_DAYS = 7;


export class BaseCollection extends Backbone.Collection {
    _allowedMethods = ['create', 'read', 'update', 'delete'];
    _prevSavedModels = [];

    /*
     * This class isn't intended to be used as generally as the parent class.
     * We shouldn't be passing pre-built collections or lots of options so we're
     * overriding the default constructor behaviour to take initialisation
     * properties which are set on the new instance, eg:
     *
     * const c = new BaseCollection({ test: 'testing' });
     * console.log(c.test);
     * > 'testing'
     */
    constructor(initProps) {
        super([]);
        for (let [key, val] of _.pairs(initProps)) {
            this[key] = val;
        }
        this.on('add', this.onItemAdded);
        this.on('sync', this.updateAfterSync);
    }

    sync(method, model, options) {
        if (_.contains(this._allowedMethods, method)) {
            this[method](model, options);
        }
    }

    /**
     * Load collection with supplied data
     *
     * Override this method to allow the collection to be loaded externally (eg:
     * by a MultiLoader) and internally (by .read()).
     *
     * Example usage:
     *
     * class MyCollection extends BaseCollection {
     *     url = 'endpoint'
     *     model = MyModel
     *
     *     read() {
     *         api.get(this.url)
     *             .then(([, data]) => this.loadWith(data));
     *     }
     *
     *     loadWith(data) {
     *         this.set(data[this.url].map(this.model.parse));
     *     }
     * }
     */
    loadWith(data) { }

    create(model, options) {}
    read(model, options) {}
    update(model, options) {}
    delete(model, options) {}

    onItemAdded(model) {
        model.on('destroy', () => this.remove(model));
    }

    updateAfterSync() {
        this._prevSavedModels = _.clone(this.models);
    }

    getAddedModels() {
        return _.difference(this.models, this._prevSavedModels);
    }

    getDeletedModels() {
        return _.difference(this._prevSavedModels, this.models);
    }

    resetAll() {
        this.add(this.getDeletedModels());
        _.invoke(this.getAddedModels(), 'destroy');
        _.invoke(this.models, 'reset');
    }

    merge(source, compareFields) {
        if (source.length === 0 && this.length > 0) {
            this.reset();
            return;
        }

        if (!_.isArray(compareFields)) {
            compareFields = [compareFields];
        }

        this.forEach(item => {
            if (!_.findWhere(source, item?.pick(compareFields))) {
                this.remove(item);
            }
        });

        source.forEach(item => {
            if (!this.findWhere(_.pick(item, compareFields))) {
                // Need to create the model first to set collection as saveable
                const model = new this.model()
                this.add(model);
                model.set(item);
            }
        });
    }

    isValid = () => {
        return this.filter(model => model.isValid()).length === this.length;
    }

    canReset = () => {
        return this.getAddedModels().length > 0 || this.getDeletedModels().length > 0 ||
            this.filter(model => model.canReset()).length > 0;
    }

    canSave = () => {
        return this.canReset() && this.isValid();
    }
}


export class BaseModel extends Backbone.Model {
    _allowedMethods = ['create', 'read', 'update', 'delete'];
    _prevSavedAttrs = {};
    _validOptions = {};

    constructor(props, options) {
        super(props, options);
        this.set({
            ...this.constructor.defaults,
            ...this.attributes,
        });
        this.metadata = new Backbone.Model({
            saveable: false,
            resetable: false,
        });
        this._prevSavedAttrs = _.clone(this.attributes);
        this.on('sync', this.updateAfterSync);
        this.on('change', this.onAttrChange);
    }

    sync(method, model, options) {
        if (_.contains(this._allowedMethods, method)) {
            this[method](model, options);
        }
    }

    /**
     * Load model with supplied data
     *
     * Override this method to allow the model to be loaded externally (eg: by a
     * MultiLoader) and internally (by .read()).
     *
     * Example usage:
     *
     * class MyModel extends BaseModel {
     *     url = 'endpoint'
     *     static defaults = { ... }
     *
     *     read() {
     *         api.get(this.url)
     *             .then(([, data]) => this.loadWith(data));
     *     }
     *
     *     loadWith(data) {
     *         this.set(MyModel.parse(data[this.url]));
     *     }
     *
     *     static parse(data) {
     *         ...
     *     }
     * }
     */
    loadWith(data) { }

    create(model, options) {}
    read(model, options) {}
    update(model, options) {}
    delete(model, options) {}

    validate = validation.validate

    updateAfterSync() {
        this._previousAttributes = _.clone(this.attributes);
        this._prevSavedAttrs = _.clone(this.attributes);
        this.onAttrChange();
    }

    hasAttrChanged = attr => {
        return this._prevSavedAttrs[attr] !== this.attributes[attr];
    }

    onAttrChange = () => {
        const changedSinceSave = !_.isEqual(this._prevSavedAttrs, this.attributes);
        this.metadata.set({
            resetable: changedSinceSave,
            saveable: changedSinceSave && this.isValid(),
        });
    }

    reset() {
        this.set(this._prevSavedAttrs);
        this.trigger('reset');
    }

    canReset = () => {
        return this.metadata.get('resetable');
    }

    canSave = () => {
        return this.metadata.get('saveable');
    }

    isInGracePeriod = () => {
        const expiryDate = this.get('expiryDate');
        return !_.isUndefined(expiryDate) && expiryDate.clone().add(
            GRACE_PERIOD_DAYS, 'day').isAfter(dayjs()) && !this.isCancelled();
    }

    isActive = (withGracePeriod=true) => {
        const expiryDate = this.get('expiryDate');
        const isActive = _.isUndefined(expiryDate) || expiryDate.isAfter(dayjs());
        return withGracePeriod ? isActive || this.isInGracePeriod() : isActive;
    }

    isCancelled = () => {
        return this.get('isCancelled');
    }
}
