import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row  } from 'react-bootstrap';

import { ButtonBar, NextButton, Notifications } from '@components';
import { BoundFormControl, Picker } from '@components/forms';
import api from '@lib/api';
import constants from '@lib/constants';
import { useAccount, useCreateAccount, useModel, useNotify } from '@lib/hooks';

import AddressForm from './address-form';

const title = 'Contact Details';
const errorMsgs = {
    'account-invalid': "We are missing some account details. Please try again.",
};


function ContactPage() {
    const { fixedAttrs: accountAttrs, model: accountModel } = useAccount();
    const { fixedAttrs: accountAddressAttrs, model: accountAddressModel } = useModel(accountAttrs.accountAddress);
    const { fixedAttrs: billingAddressAttrs, model: billingAddressModel } = useModel(accountAttrs.billingAddress);
    const { fixedAttrs: invoiceAddressAttrs, model: invoiceAddressModel } = useModel(accountAttrs.invoiceAddress);
    const { fixedAttrs: deliveryAddressAttrs, model: deliveryAddressModel } = useModel(accountAttrs.deliveryAddress);
    const { model: adminDetailsModel, fixedAttrs: adminDetailsAttrs } = useModel(accountAttrs.adminDetails);
    const [isLoading, setIsLoading] = useState(false);
    const [showAdminEmailPopup, setShowAdminEmailPopup] = useState(false);
    const [showSubscribePopup, setShowSubscribePopup] = useState(false);
    const { model, onSubmitContact } = useCreateAccount();
    const [CreateAccountAlert] = useNotify({ model, errorMsgs, withScroll: true });

    // Residential customers must enter their house number/street separately,
    // as these fields are used for emergency contact details registration.
    accountAddressModel.set('separateAddressFields', accountModel.isResidential);

    useEffect(() => {
        accountModel.set({
            accountPremises: accountAddressAttrs.premises,
            accountThoroughfare: accountAddressAttrs.thoroughfare,
        });

        if (accountModel.isResidential) {
            // Ensure the first line of the address is set,
            // otherwise the API will see it as invalid.
            accountAddressModel.set('address1',
                `${accountAddressAttrs.premises} ${accountAddressAttrs.thoroughfare}`.trim());
        }
    }, [accountAddressAttrs, accountModel]);

    useEffect(() => {
        if (billingAddressAttrs.useAccountAddress) billingAddressModel.resetDefault();
    }, [billingAddressAttrs.useAccountAddress, billingAddressModel]);

    useEffect(() => {
        if (invoiceAddressAttrs.useAccountAddress) invoiceAddressModel.resetDefault();
    }, [invoiceAddressAttrs.useAccountAddress, invoiceAddressModel]);

    useEffect(() => {
        if (deliveryAddressAttrs.useAccountAddress) deliveryAddressModel.resetDefault();
    }, [deliveryAddressAttrs.useAccountAddress, deliveryAddressModel]);

    const checkSubscribed = () => {
        accountAttrs.emailSubscribe ? onSubmitContact() : setShowSubscribePopup(true);
    };

    const confirmSubscribe = (confirm) =>  {
        accountModel.set('emailSubscribe', confirm);
        onSubmitContact();
    };

    const onNextButtonClicked = () => {
        if (accountAttrs.hasAdmin && accountModel.isResidential) {
            if (!isLoading) {
                setIsLoading(true);

                api.v1.post('onboarding/accounts/check-available', { email: adminDetailsAttrs.email }, 'json')
                    .then(({ available }) => {
                        if (available) {
                            checkSubscribed();
                        } else {
                            setShowAdminEmailPopup(true);
                        }
                    })
                    // As not to stall the signup process any further,
                    // proceed as normal in the event of an API error
                    // as the email will be checked again later anyway.
                    .catch(checkSubscribed)
                    .finally(() => setIsLoading(false));
            }
        } else {
            checkSubscribed();
        }
    };

    return (
        <div>
            <h2>Contact Details</h2>

            <Notifications />

            <CreateAccountAlert />

            <p>We are required by the regulator to provide emergency contact
            information for any telephone service we provide which can be used
            to contact the emergency services.</p>

            <Row className="g-2">
                {accountModel.isResidential &&
                    <Col md={2}>
                        <Picker bind={accountAttrs} field="title">
                            <option value="">Title</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Ms">Ms</option>
                            <option value="Miss">Miss</option>
                            <option value="Dr">Dr</option>
                        </Picker>
                    </Col>
                }

                <Col md={accountModel.isResidential ? 4 : 6}>
                    <BoundFormControl
                        bind={accountAttrs}
                        field="firstname"
                        placeholder="First name"
                    />
                </Col>

                <Col md={accountModel.isResidential ? 4 : 6}>
                    <BoundFormControl
                        bind={accountAttrs}
                        field="surname"
                        placeholder="Surname"
                    />
                </Col>

                <Col md={6}>
                    <BoundFormControl
                        bind={accountAttrs}
                        field="phone"
                        placeholder="Mobile number (optional)"
                    />
                </Col>
            </Row>

            <hr />

            <AddressForm addressModel={accountAddressModel}>
                <BoundFormControl
                    bind={billingAddressAttrs}
                    field="useAccountAddress"
                    type="checkbox"
                    id="useAccountAddressForBilling"
                    label="Use this address for billing"
                />

                {!accountModel.isResidential &&
                    <BoundFormControl
                        bind={invoiceAddressAttrs}
                        field="useAccountAddress"
                        type="checkbox"
                        id="useAccountAddressForInvoice"
                        label="Use this address for invoice"
                    />
                }

                {accountModel.hasHardware &&
                    <BoundFormControl
                        bind={deliveryAddressAttrs}
                        field="useAccountAddress"
                        type="checkbox"
                        id="useAccountAddressForDelivery"
                        label="Use this address for delivery"
                    />
                }
            </AddressForm>

            <hr />

            {!billingAddressAttrs.useAccountAddress &&
                <React.Fragment>
                    <p><strong>Billing Address</strong></p>
                    <AddressForm addressModel={billingAddressModel} />
                    <hr />
                </React.Fragment>
            }

            {!invoiceAddressAttrs.useAccountAddress &&
                <React.Fragment>
                    <p><strong>Invoice Address</strong></p>
                    <AddressForm addressModel={invoiceAddressModel} />
                    <hr />
                </React.Fragment>
            }

            {!deliveryAddressAttrs.useAccountAddress &&
                <React.Fragment>
                    <p><strong>Delivery Address</strong></p>
                    <AddressForm addressModel={deliveryAddressModel} />
                    <hr />
                </React.Fragment>
            }

            {accountModel.hasHardware &&
                <React.Fragment>
                    <p><strong>Delivery Notifications</strong></p>
                    <p>Please provide an email address and mobile phone number
                    for delivery updates.</p>

                    <Row>
                        <Col md={6}>
                            <BoundFormControl
                                bind={accountAttrs}
                                field="deliveryNotificationMobile"
                                placeholder="Mobile number"
                            />
                        </Col>
                        <Col md={6}>
                            <BoundFormControl
                                bind={accountAttrs}
                                field="deliveryNotificationEmail"
                                placeholder="Email address"
                            />
                        </Col>
                    </Row>

                    <hr />
                </React.Fragment>
            }

            <p><strong>Memorable Word</strong></p>
            <p>Please enter a memorable word that will be used for account verification when you call Customer Services.</p>
            <Row className="g-2">
                <Col md={6}>
                    <BoundFormControl
                        bind={accountAttrs}
                        field="memorablePhrase"
                        placeholder="Word"
                    />
                </Col>
                <Col md={6}>
                    <BoundFormControl
                        bind={accountAttrs}
                        field="memorablePhraseHint"
                        placeholder="Hint"
                    />
                </Col>
            </Row>

            <hr />

            {accountModel.isResidential &&
                <React.Fragment>
                    <p><strong>Trusted Helper</strong></p>
                    <BoundFormControl
                        bind={accountAttrs}
                        field="hasAdmin"
                        id="hasAdmin"
                        type="checkbox"
                        label="I would like to create an additional login for a relative/friend"
                    />

                    <hr />

                    {accountAttrs.hasAdmin &&
                        <React.Fragment>
                            <p><small><strong>Please note:</strong> this additional login will
                            have full access to your Voipfone account. It should only be provided
                            to somebody you trust.</small></p>

                            <Row className="g-2">
                                <Col md={6}>
                                    <BoundFormControl
                                        bind={adminDetailsAttrs}
                                        field="firstname"
                                        name="adminFirstname"
                                        placeholder="First name"
                                    />
                                </Col>
                                <Col md={6}>
                                    <BoundFormControl
                                        bind={adminDetailsAttrs}
                                        field="surname"
                                        name="adminSurname"
                                        placeholder="Surname"
                                    />
                                </Col>
                                <Col md={6}>
                                    <BoundFormControl
                                        bind={adminDetailsAttrs}
                                        field="email"
                                        name="adminEmail"
                                        placeholder="Email address"
                                    />
                                </Col>
                                <Col md={6}>
                                    <BoundFormControl
                                        bind={adminDetailsAttrs}
                                        field="phone"
                                        name="adminPhone"
                                        placeholder="Phone number (optional)"
                                    />
                                </Col>
                            </Row>

                            <hr />
                        </React.Fragment>
                    }
                </React.Fragment>
            }

            <p>We'd love to keep you updated by email about service changes,
            products and new features. We will never share your personal
            information with any third party and you can change this decision at
            any time from your Voipfone Control Panel.</p>

            <BoundFormControl
                bind={accountAttrs}
                field="emailSubscribe"
                type="checkbox"
                id="emailSubscribe"
                label="Yes please, I'd like to hear about service changes, products and new features by email."
            />

            <hr />

            <BoundFormControl
                bind={accountAttrs}
                field="acceptTerms"
                type="checkbox"
                id="acceptTerms"
                label={
                    <small>I have read and agree to both the <a
                    href={`${constants.BASE_VF_URL}/policies/terms`} target="_blank"
                    rel="noopener noreferrer">Voipfone Terms and Conditions</a> and
                    the <a href={`${constants.BASE_VF_URL}/Usage_Policy.php`}
                    target="_blank" rel="noopener noreferrer">Voipfone Usage Policy</a>.
                    I understand that this service allows calls to the emergency
                    services. However, I understand that calls will fail if there
                    is a power cut or my broadband connection fails.
                    <br />
                    I also confirm that I have access to an alternative means of contacting
                    the emergency services (such as a mobile phone) if needed.</small>
                }
            />

            {accountModel.hasHardware &&
                <BoundFormControl
                    bind={accountAttrs}
                    field="deliveryAcceptTerms"
                    type="checkbox"
                    id="deliveryAcceptTerms"
                    label={
                        <small>I have read and agree to the <a
                        href={`${constants.BASE_VF_URL}/Hardware_Terms_And_Conditions.php`}
                        target="_blank" rel="noopener noreferrer">Voipfone Hardware Terms
                        and Conditions</a>.</small>
                    }
                />
            }

            <br />

            <Modal
                show={showSubscribePopup}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Subscribe?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you don't want to hear about service changes,
                    products and new features by email? We only send the occasional message,
                    and it's the only way we can keep you up to date.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => confirmSubscribe(true)}>
                        Oh, go on then
                    </Button>
                    <Button variant="secondary" onClick={() => confirmSubscribe(false)}>
                        Do not email me
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showAdminEmailPopup}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Unavailable</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>We're sorry, but {adminDetailsAttrs.email} is already registered.
                    Please enter a different email address.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setShowAdminEmailPopup(false);
                        adminDetailsModel.set('email', '');
                    }}>OK</Button>
                </Modal.Footer>
            </Modal>

            <ButtonBar>
                <NextButton disabled={!accountAttrs.plan || !accountModel.isValid() || isLoading} onClick={onNextButtonClicked} />
            </ButtonBar>
        </div>
    );
}


export default ContactPage;

export {
    title,
};
