import React from 'react';
import { Table } from 'react-bootstrap';
import { CheckLg as Check } from 'react-bootstrap-icons';

import constants from '@lib/constants';
import fmt from '@lib/formatters';
import { useAccount, usePriceList } from '@lib/hooks';
import { SERVICES_RESIDENTIAL as services } from '@lib/services';

import planFmt from './plan-formatters';
import PlanSelectorForm from './plan-selector-form';


function ResidentialPlansDesktop({ plans }) {
    const { getTrialProductOf } = usePriceList();
    const { fixedAttrs: { plan: activePlan } } = useAccount();

    return (
        <Table borderless className="comparison m-auto mb-4">
            <thead>
                <tr>
                    <th className="bg-plan-lavender"></th>
                    {plans.map(plan => (
                        <th
                            key={plan.uid}
                            className={`text-center bg-plan-${planFmt.getBgColour(activePlan?.product, plan)}`}
                            style={{ width: '23%' }}
                        >
                            Voipfone<br />{plan.shortName}
                        </th>
                    ))}
                </tr>
                <tr>
                    <td className="bg-plan-lavender">
                        Per month inc VAT
                    </td>
                    {plans.map(plan => (
                        <td
                            key={plan.uid}
                            className={`text-center h1 bg-plan-${planFmt.getBgColour(activePlan?.product, plan)}`}
                        >
                            &pound;{fmt.price(Math.round(plan.renewalPrice * 100 * constants.VAT_RATE) / 100, true)}
                        </td>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr className="border-bottom border-light">
                    <td className="text-nowrap">
                        <p className="fw-bold text-body m-0">
                            Calls to UK mobiles and landlines
                        </p>
                    </td>

                    {plans.map(plan => (
                        <td
                            key={plan.uid}
                            className={`text-center bg-plan-${planFmt.getBgColour(activePlan?.product, plan)}-lightest`}
                        >
                            {planFmt.getMinutesLabel(plan.inclusiveServices)}
                        </td>
                    ))}
                </tr>

                {services.map((service, index) => (
                    <tr
                        key={index}
                        className="border-bottom border-light"
                    >
                        <td className="text-nowrap">
                            <p className="fw-bold text-body m-0">
                                {service.name}
                            </p>
                        </td>

                        {plans.map(plan => (
                            <td
                                key={plan.uid}
                                className={`text-center bg-plan-${planFmt.getBgColour(activePlan?.product, plan)}-lightest`}
                            >
                                <Check className="text-success" />
                            </td>
                        ))}
                    </tr>
                ))}

                <tr className="border-bottom border-light">
                    <td className="text-nowrap">
                        <p className="fw-bold text-body m-0">
                            Call Recording
                        </p>
                    </td>

                    {plans.map(plan => (
                        <td
                            key={plan.uid}
                            className={`text-center bg-plan-${planFmt.getBgColour(activePlan?.product, plan)}-lightest`}
                        >
                            3p/min
                        </td>
                    ))}
                </tr>
            </tbody>
            <tfoot>
                <tr className="text-center text-nowrap">
                    <td rowSpan="3"></td>
                    {plans.map(plan => (
                        <td key={plan.uid}>
                            {planFmt.getContractLabel(plan)}
                        </td>
                    ))}
                </tr>
                <tr>
                    {plans.map(plan => (
                        <td key={plan.uid} className="align-top">
                            <PlanSelectorForm
                                plan={plan}
                                trialPlan={getTrialProductOf(plan)}
                            />
                        </td>
                    ))}
                </tr>
            </tfoot>
        </Table>
    );
}


export default ResidentialPlansDesktop;
