import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import _ from 'underscore';

import { destinationName } from '@lib/formatters';
import { MultiLoader, useDidMount, useLoader, useWillUnmount } from '@lib/hooks';
import { convertType } from '@lib/utils';

import BoundFormControl from './bound-form-control';

import './picker.scss';

const OPTION_CUSTOM = 'custom';


function PickerInput({ bind, field, onCancel, ...props }) {
    const pickerValue = bind?.[field];
    const expiredValue = destinationName(pickerValue);
    const hasExpired = pickerValue && !bind?.model.hasAttrChanged(field) &&
        expiredValue !== pickerValue;

    return (
        <div className="input-group">
            <BoundFormControl
                bind={bind}
                field={field}
                name={`${props.name || field}Custom`}
                readOnly={hasExpired}
                {...hasExpired ? {value: `${expiredValue} - Expired` } : {}}
            />

            <span className="input-group-btn">
                <Button
                    variant="light"
                    onClick={onCancel}
                >
                    <XLg />
                </Button>
            </span>
        </div>
    );
}


function Picker({ bind, field, children, listenForChanges=false, ...props }) {
    const { loader, isLoading, listeners } = useLoader({ loaderCls: MultiLoader, reuseData: true });
    const [numChanges, setNumChanges] = useState(0);
    const [showCustom, setShowCustom] = useState(false);
    const [options, setOptions] = useState([]);
    const optGroups = _.compact(React.Children.toArray(children));
    const pickerElement = useRef(null);
    const pickerValue = bind?.[field];

    useDidMount(() => {
        listeners.forEach(item => item.on('add remove sync', () => {
            setNumChanges(state => state + 1);
        }, loader));
    });

    useEffect(() => {
        const options = [...pickerElement?.current?.getElementsByTagName('option')];
        const values = options.map(item => convertType(item.value, props.dataType));

        // Keep a reference of valid picker values for use within validation
        bind.model._validOptions[field] = values;
        setOptions(values);
    }, [isLoading, numChanges, pickerValue]);

    useEffect(() => {
        const isCustom = bind.model.previous(field) === OPTION_CUSTOM ||
            !_.contains(options, pickerValue);

        if (pickerValue === OPTION_CUSTOM) {
            onCustomChanged(true);
        } else {
            setShowCustom(!isLoading && isCustom);
        }
    }, [options, pickerValue]);

    useWillUnmount(() => {
        listeners.forEach(item => item.off('add remove sync', null, loader));
    });

    const onCustomChanged = showCustom => {
        bind.model.set(field, _.first(options));
        setShowCustom(showCustom);
    };

    return (
        <span className="picker" ref={pickerElement}>
            <BoundFormControl
                style={{ display: showCustom ? 'none' : 'inline-block' }}
                as={Form.Select}
                disabled={isLoading}
                bind={bind}
                field={field}
                {...props}
            >
                {optGroups.map(child =>
                    React.cloneElement(child, { ...child.props, loader })
                )}
            </BoundFormControl>

            {showCustom &&
                <PickerInput
                    bind={bind}
                    field={field}
                    onCancel={() => onCustomChanged(false)}
                    {...props}
                />
            }
        </span>
    );
}


Picker.NoneOption = () => <option value="">None</option>;
Picker.PleaseSelectOption = () => <option value="">Please Select &hellip;</option>;
Picker.CustomNumberOption = () => <option value={OPTION_CUSTOM}>Custom Number</option>;
Picker.NumberRange = ({ min = 0, max, step = 1 }) =>
    _.range(min, max + 1, step).map(i => <option key={i} value={i} >{i}</option>);


export default Picker;
