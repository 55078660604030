import _ from 'underscore';

const URL = 'https://api.getAddress.io/';


class Address {
    async fromPostcode(postcode) {
        return this.sendRequest('GET', `find/${postcode}`, {
            'api-key': process.env.REACT_APP_ADDRESS_TOKEN,
            'expand': true,
        });
    }

    sendRequest(method, path, data={}) {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            const params = _.pairs(data)
                .map(p => `${encodeURIComponent(p[0])}=${encodeURIComponent(p[1])}`)
                .join('&');

            req.open(method, `${URL}${path}?${params}`);
            req.onload = e => {
                if (req.status === 200) {
                    resolve(JSON.parse(req.responseText));
                } else {
                    reject(e);
                }
            };
            req.send(data);
        });
    }
}


export default new Address();
