import React from 'react';
import { Table } from 'react-bootstrap';

import { useBasket } from '@lib/hooks';

import BasketEditButton from './basket-edit-button';
import PriceLabel from './price-label';


function BasketDesktop({ items }) {
    const basket = useBasket();

    return (
        <Table className="mt-2 mb-2" borderless>
            <thead>
                <tr>
                    <th>Item</th>
                    <th className="text-end">One-off</th>
                    <th className="text-end">Monthly</th>
                    <th className="text-end">Pay today</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {items.map(({ product, quantity }) => (
                    <tr key={product.uid} className="small border-top border-bottom">
                        <td>{quantity} x {product.name}</td>
                        <td className="text-end">
                            <PriceLabel price={product.oneOffPrice * quantity} />
                        </td>
                        <td className="text-end">
                            <PriceLabel price={product.renewalPrice * quantity} />
                        </td>
                        <td className="text-end">
                            <PriceLabel price={product.payTodayPrice * quantity} />
                        </td>
                        <td className="text-end">
                            <BasketEditButton product={product} />
                        </td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr className="small text-end">
                    <td className="text-start">Sub-total</td>
                    <td>
                        <PriceLabel price={basket.totalOneOffExVat()} />
                    </td>
                    <td>
                        <PriceLabel price={basket.totalRenewalPriceExVat()} />
                    </td>
                    <td>
                        <PriceLabel price={basket.totalExVat()} />
                    </td>
                </tr>
                <tr className="small text-end">
                    <td className="text-start">VAT</td>
                    <td>
                        <PriceLabel price={basket.totalOneOffVat()} />
                    </td>
                    <td>
                        <PriceLabel price={basket.totalRenewalPriceVat()} />
                    </td>
                    <td>
                        <PriceLabel price={basket.totalVat()} />
                    </td>
                </tr>
                <tr className="text-end">
                    <td className="fw-bold text-start">Total</td>
                    <td>
                        <PriceLabel price={basket.totalOneOffIncVat()} />
                    </td>
                    <td>
                        <PriceLabel price={basket.totalRenewalPriceIncVat()} />
                    </td>
                    <td className="fw-bold">
                        <PriceLabel price={basket.totalIncVat()} />
                    </td>
                </tr>
            </tfoot>
        </Table>
    );
}


export default BasketDesktop;
