import React, { useState } from 'react';
import _ from 'underscore';

import { ConfirmDialog, Dialog } from '@components/dialog';


/**
 * A hook to use a dialog
 *
 * Example usage:
 * <code>
 * function MyComponent() {
 *     const [SignoutDialog, showDialog] = useDialog({
 *         title: 'Confirm Something', // Optional title
 *         message: 'Are you sure you want to do this?',
 *         // message: () => `Message can also be ${calculated} by passing a function`,
 *         onOkClicked: () => doStuff(), // Optional
 *         onCancelClicked: () => doStuff(), // Optional
 *     });
 *     return (
 *         <div>
 *             ... component content ...
 *             <SignoutDialog />
 *         </div>
 *     );
 * }
 * </code>
 *
 * @param {string/func} message  The string (or function which returns a string) to display
 *                               in the body of the dialog
 * @param {*} props See @components/dialog/dialog|confirm-dialog.
 */
function useDialog({ message, ...props }) {
    const [show, setShow] = useState(false);
    const Component = props?.onOkClicked || props?.onCancelClicked ?
        ConfirmDialog : Dialog;

    const showDialog = e => {
        e?.preventDefault?.();
        setShow(true);
    };

    const DialogComponent = () => (
        show && <Component
            message={_.isFunction(message) ? message() : message}
            onExit={() => setShow(false)}
            {...props}
        />
    );

    return [DialogComponent, showDialog];
}


export default useDialog;
