import React from 'react';
import { useAccount } from '@lib/hooks';

import PortingPageBusiness from './porting-page-business';
import PortingPageResidential from './porting-page-residential';

const title = 'Port Your Existing Numbers';


function PortingPage() {
    const { model: accountModel } = useAccount();
    const PortingPage = accountModel.isResidential ? PortingPageResidential : PortingPageBusiness;
    return <PortingPage />;
}


export default PortingPage;

export {
    title,
};
