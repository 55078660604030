import { useState } from 'react';
import _ from 'underscore';

import useDidMount from './use-did-mount';
import useWillUnmount from './use-will-unmount';


export function useCollection(collOrCollCls, params={}) {
    const isClass = _.isFunction(collOrCollCls);
    const { fetch=isClass, init: initialVals=null, context=null, loader=null } = params;

    const build = coll => ({
        fixedList: coll.map(model => ({
            ...model.attributes,
            cid: model.cid,
            model,
        })),
        savedList: coll.map(model => ({
            ...model._prevSavedAttrs,
            cid: model.cid,
            model,
        })),
        collection: coll,
        canReset: coll.canReset,
        canSave: coll.canSave,
    })

    // Event ID is needed so that collections can be reused without removing
    // listeners from other components on unmount
    const [eventId,] = useState(_.uniqueId('e'));
    const [inited, setInited] = useState(false);
    const [coll,] = useState(inited ? null :
        (isClass ? new collOrCollCls(initialVals) : collOrCollCls));
    const [boundList, setBoundList] = useState(inited ? null : build(coll));

    if (!inited && loader) loader.add(coll, fetch);
    if (!inited) setInited(true);

    useDidMount(() => {
        coll.context = isClass ? context : coll.context;
        coll.on('add change remove reset sync update',
            () => setBoundList(build(coll)), eventId);

        if (!loader && fetch) coll.fetch();
    });

    useWillUnmount(() => coll.off(null, null, eventId));

    return boundList;
}


export default useCollection;
