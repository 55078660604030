import { BaseModel } from '@lib/models';


class StateModel extends BaseModel {
    static defaults = {
        name: '',
        code: '',
        country: '',
    }

    static parse(data) {
        return {
            name: String(data[2]),
            code: String(data[1]),
            country: String(data[0]),
        };
    }
}


export default StateModel;
