import { BaseModel } from '@lib/models';


class NumberFilterModel extends BaseModel {
    static defaults = {
        type: '',
        area: '',
        filter: 'standard',
    }

    validation = {
        area: {
            required: () => !this.get('type'),
        },
    }
}


export default NumberFilterModel;
