import parsePhoneNumberFromString from 'libphonenumber-js';
import _ from 'underscore';
import _s from 'underscore.string';

import format from './formatters';
import utils from './utils'


export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_SYMBOLS = '~`! @#$%^&*()_-+={[}]|\\:;"\'<,>.?/';

export const ASCII_LOWERCASE = 'abcdefghijklmnopqrstuvwxyz';
export const ASCII_UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const ASCII_LETTERS = ASCII_LOWERCASE + ASCII_UPPERCASE;

export const patterns = {
    ukPostcode: /^[A-Z]{1,2}[0-9]([0-9]|[A-Z])? {0,1}[0-9][A-Z]{2}$/i,
    numberBalance: /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d{1,2})?$/,
    alphanumeric: /^[a-zA-Z0-9]+$/,
};


/**
 * Validate a password against our current rules
 *
 * Note: If you make changes here, don't forget to mirror them in the API!
 *
 * We currently require a password with a minimum of 8 characters, including at
 * least one character from each of: lowercase letters, uppercase letters, digits,
 * and ascii symbols.  Exits immediately on failure of a test so multiple errors
 * are not detected.  We allow accented and other extended charset characters but
 * don't consider them as part of the standard character groups, so at least one
 * from each of the defined groups is still required.
 *
 * @param password: The password to test
 * @return: None if successful
 * @raise ValueError: The specific failure is described in the error message
 */
export function password(val) {
    const characterGroups = [
        ASCII_LOWERCASE,
        ASCII_UPPERCASE,
        '0123456789',
        PASSWORD_SYMBOLS,
    ];
    const characterGroupLabels = [
        'lowercase letter (a - z)',
        'uppercase letter (A - Z)',
        'number (0 - 9)',
        'symbol (' + PASSWORD_SYMBOLS + ')',
    ];

    // Password cannot be empty - we catch this first
    if (!val || !val.length || val.length < 1) {
        return 'Password is required';
    }

    // Next, we check for minimum password length
    if (val.length < PASSWORD_MIN_LENGTH) {
        return `Password must be at least ${PASSWORD_MIN_LENGTH} characters`;
    }

    // Finally, we report on missing character groups, one at a time
    try {
        _.zip(characterGroups, characterGroupLabels).forEach(pair => {
            if (_.intersection(pair[0], val).length < 1) {
                throw new Error('Password must contain at least one ' + pair[1]);
            }
        });
    } catch (e) {
        return e.message;
    }
}


/**
 * Ensure a string only contains printable ASCII characters
 *
 * @param  String  val       The value to test
 * @return String|undefined  The error message or null if valid
 */
export function printableAscii(val) {
    if (!val) return;

    var invalidChars = [];

    for (var i = 0; i < val.length; i += 1) {
        var asciiCode = val.charCodeAt(i);

        if (asciiCode < 32 || asciiCode > 126) {
            invalidChars.push(val[i]);
        }
    }

    if (invalidChars?.length > 0) {
        return "The following characters are not allowed: "
            + _.uniq(invalidChars).join(', ');
    }
}


/**
 * Ensure a string only contains ASCII letters (same as Python's string.ascii_letters constant)
 */
export function asciiLetters(val) {
    if (!val) return;

    for (const char of val) {
        if (!ASCII_LETTERS.includes(char)) {
            return 'Value contains invalid character(s)';
        }
    }
}


/**
 * Full validation of a postcode
 *
 * Check whether the postcode matches what BT expects for 999
 * emergency records.
 *
 * Code ported from server: new/vf/site/api/srv/bb_line_check.py
 *
 * @param  String  val  The postcode to be validated
 * @return String|undefined  String containing the validation error
 */
export function ukPostcode(val) {
    var postcode = format.ukPostcode(val, true),
        parts = postcode.split(' '),
        msg;

    if (!postcode.match(patterns.ukPostcode)) {
        return "Invalid postcode format";
    }

    // The format fits, now run specific character checks for both
    // halves.
    if (_.contains('QVX', parts[0][0]) ||
        (utils.isAlpha(parts[0][1]) &&
            _.contains('IJZ', parts[0][1])) ||
        (parts[0].length > 2 && utils.isAlpha(parts[0][2]) &&
            !_.contains('ABCDEFGHJKSTUW', parts[0][2]))) {
        return "Not a recognised postcode";
    }

    _s.chars('CIKMOV').forEach(function (chr) {
        if (_.contains(parts[1], chr)) {
            msg = "Not a recognised postcode ";
        }
    });

    if (msg) {
        return msg;
    }
}


/**
 * Ensure a string is unique
 *
 * @param  String            val        The value to test
 * @param  String            attr       The key to look up in colOrArr
 * @param  Array|Collection  colOrArr   A collection or array of objects
 * @param  Model|Object      modelOrObj A model or object to exclude
 * @return String|undefined  The error message or null if valid
 */
export function unique(val, attr, collOrArr, obj) {
    const arr = collOrArr?.toJSON?.() || collOrArr;

    if (!_.isString(val) || !_.isString(attr) || !_.isArray(arr)) return;

    const items = arr.filter(item => !obj || !_.isMatch(item, obj));
    const normalise = item => item?.trim().toLowerCase();
    const values = items.map(item => normalise(item[attr]));

    if (_.contains(values, normalise(val))) {
        return `This is a duplicate ${attr}`;
    }
}


export function phoneNumber(val) {
    const msg = 'A valid phone number is required';

    // We need to check whether the string contains letters first,
    // because our phone number lib strips them and no error is
    // detected
    if (!val || val.match(/[a-z]/i)) {
        return msg;
    }

    const number = parsePhoneNumberFromString(val, 'GB');

    if (_.isUndefined(number) || !number.isValid()) {
        return msg;
    }
}


export function phoneNumberByCountry(val, country) {
    const msg = 'A valid phone number is required';

    if (!val || val.match(/[a-z]/i)) {
        return msg;
    }

    const number = parsePhoneNumberFromString(val, country);

    if (_.isUndefined(number) || !number.isValid()) {
        return msg;
    } else if (number.country !== country) {
        return 'Invalid phone number country';
    }
}


export function picker(val, optionsOrAttr) {
    const validOptions = _.isString(optionsOrAttr) ?
        this._validOptions[optionsOrAttr] : optionsOrAttr;

    if (!val || !_.isArray(validOptions)) return;

    const hasCustom = _.contains(validOptions, 'custom');
    const hasOption = _.contains(validOptions, val) && val !== 'custom';

    if (hasCustom && !hasOption) {
        return phoneNumber(val);
    } else if (!hasOption) {
        return 'A valid option is required';
    }
}


export default {
    asciiLetters,
    patterns,
    password,
    phoneNumber,
    phoneNumberByCountry,
    picker,
    printableAscii,
    ukPostcode,
    unique,
};
