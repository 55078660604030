import React, { useContext } from 'react';

import { AppContext } from '@lib/store';

import NotificationAlert from './notification-alert';


function Notifications() {
    const { context } = useContext(AppContext);

    switch (context?.notify) {
        case 'skip-plan':
            return <NotificationAlert variant="success" title="Plan Selection Skipped">We've chosen a 0 user Flex plan
            for now so you can get started. You can change your plan at any time in the Voipfone Control Panel.</NotificationAlert>

        case 'skip-all':
            return <NotificationAlert variant="success" title="Common Setup Tasks">We've chosen a 0 user Flex
            plan for now so you can get started. You can change your plan or buy phone numbers at any time in the
            Voipfone Control Panel.</NotificationAlert>

        case 'skip-unchanged':
            return <NotificationAlert variant="success" title="Tasks Skipped">We haven't changed your previous selections.
            If you want to change your plan or numbers, you can do that on the relevant page or at any time in the Voipfone
            Control Panel.</NotificationAlert>

        default:
            return '';
    }
}


export default Notifications;
