import React from 'react';
import { Button } from 'react-bootstrap';


function ResetButton({ bind, canReset, label='Reset' }) {
    return (
        <Button
            type="reset"
            variant="secondary"
            disabled={!canReset()}
            onClick={e => {
                if (bind) {
                    e.preventDefault();
                    bind.reset();
                }
            }}
        >{label}</Button>
    );
}


export default ResetButton;
