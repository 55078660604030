import { useEffect } from 'react';
import _ from 'underscore';
import Cookies from 'universal-cookie';

import {
    CANCELLED_RESPONSES,
    SUCCESSFUL_RESPONSES,
    VALID_RESPONSES,
    STATUS_INVALID,
    CreateAccountModel,
    PaymentModel,
} from '@components/onboarding-account';
import analytics from '@lib/analytics';
import { useAccount, useDidMount, useModel, useSend, useWillUnmount } from '@lib/hooks';


function useCreateAccount() {
    const { model: accountModel } = useAccount();
    const hasBasket = accountModel.basketCount > 0;
    const { fixedAttrs, model } = useModel(hasBasket ? PaymentModel : CreateAccountModel, {
        context: { accountModel },
    });
    const send = useSend();

    const onAccountCreated = () => {
        analytics.sendEvent(hasBasket ? 'purchase' : 'sign_up', model.analyticsData);
        new Cookies().remove('web-id');
    };

    useDidMount(() => {
        if (hasBasket) window.addEventListener('message', onMessage);
    });

    useEffect(() => {
        if (_.contains(SUCCESSFUL_RESPONSES, fixedAttrs.status)) {
            onAccountCreated();
            send('NAVIGATE-NEXT');
        } else if (_.contains(CANCELLED_RESPONSES, fixedAttrs.status)) {
            send('NAVIGATE-PREV');
        } else if (fixedAttrs.status === STATUS_INVALID) {
            send('NAVIGATE-CONTACT-US');
        }
    }, [send, onAccountCreated, fixedAttrs.status]);

    useWillUnmount(() => {
        if (hasBasket) window.removeEventListener('message', onMessage);
    });

    const onMessage = (e) => {
        var isValid = process.env.REACT_APP_API_LEGACY_BASE_URL.indexOf(e.origin) === 0 &&
                _.contains(VALID_RESPONSES, e.data);
        if (isValid) model.set({ url: '', status: e.data });
    };

    return {
        fixedAttrs,
        model,
        onSubmitContact: () => {
            if (hasBasket) {
                send('NAVIGATE-NEXT');
            } else {
                accountModel.once('sync', () => model.save()).save();
            }
        },
    };
}


export default useCreateAccount;
