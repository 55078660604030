import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { CheckLg as Check } from 'react-bootstrap-icons';

import constants from '@lib/constants';
import fmt from '@lib/formatters';
import { useAccount, usePriceList } from '@lib/hooks';
import { SERVICES_RESIDENTIAL as services } from '@lib/services';

import planFmt from './plan-formatters';
import PlanSelectorForm from './plan-selector-form';


function ResidentialPlansMobile({ plans }) {
    const { getTrialProductOf } = usePriceList();
    const { fixedAttrs: { plan: activePlan } } = useAccount();

    return (
        <Row xs={1} lg={2} className="g-4 justify-content-center">
            {plans.map(plan => {
                const bgColour = planFmt.getBgColour(activePlan?.product, plan);

                return (
                    <Col key={plan.uid} className="mt-4 mb-4">
                        <Card className={`h-100 border border-${bgColour}-lighter`}>
                            <Card.Header className={`text-center rounded-top bg-plan-${bgColour}`}>
                                <h3 className="mt-3 mb-3">Voipfone {plan.shortName}</h3>
                                <p className="mt-3 mb-3 h1">&pound;{fmt.price(Math.round(plan.renewalPrice * 100 * constants.VAT_RATE) / 100, true)}</p>
                                <p className="mt-3 mb-3 fw-bold">Per Month</p>
                            </Card.Header>

                            <div
                                className={`p-2 border-bottom bg-plan-${bgColour}-lightest border-light`}
                            >
                                <Row>
                                    <Col>
                                        <p className="text-body fw-bold m-0">
                                            Calls to UK mobiles and landlines
                                        </p>
                                    </Col>
                                    <Col xs={4} className="text-end">
                                        {planFmt.getMinutesLabel(plan.inclusiveServices)}
                                    </Col>
                                </Row>
                            </div>

                            {services.map((service, index) => (
                                <div
                                    key={index}
                                    className={`p-2 border-bottom bg-plan-${bgColour}-lightest border-light`}
                                >
                                    <Row>
                                        <Col>
                                            <p className="text-body fw-bold m-0">
                                                {service.name}
                                            </p>
                                        </Col>
                                        <Col xs={2} className="text-end">
                                            <Check className="text-success" />
                                        </Col>
                                    </Row>
                                </div>
                            ))}

                            <div className={`p-2 border-bottom bg-plan-${bgColour}-lightest`}>
                                <Row>
                                    <Col>
                                        <p className="text-body fw-bold m-0">
                                            Call Recording
                                        </p>
                                    </Col>
                                    <Col xs={2} className="text-end">
                                        3p/min
                                    </Col>
                                </Row>
                            </div>

                            <Card.Footer className="h-100 pb-3">
                                <Card.Text className="text-center">
                                    {planFmt.getContractLabel(plan)}
                                </Card.Text>

                                <PlanSelectorForm
                                    plan={plan}
                                    trialPlan={getTrialProductOf(plan)}
                                />
                            </Card.Footer>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
}


export default ResidentialPlansMobile;
