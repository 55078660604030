import React, { useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import api from '@lib/api';
import { useDidMount } from '@lib/hooks';
import SimpleWin from '@components/simple-win';


const REDIRECT_AFTER_SECS = 3;


function VerifyAccount() {
    const [hasError, setHasError] = useState(false);
    const [hasVerified, setHasVerified] = useState(false);
    const { token } = useParams();

    useDidMount(() => {
        api.v1.post('onboarding/accounts/verify', { token })
            .then(() => {
                setHasVerified(true);
                setTimeout(() => location.href = '/', REDIRECT_AFTER_SECS * 1000);
            })
            .catch(() => setHasError(true));
    });

    return (
        <SimpleWin>
            <h1>Verify Your Account</h1>

            {!hasError && !hasVerified &&
                <Spinner variant="purple" animation="border" />
            }

            {hasError &&
                <Alert variant="danger">
                    Sorry that verification link is incorrect or has expired.
                </Alert>
            }

            {hasVerified &&
                <React.Fragment>
                    <Alert variant="success">
                        Your email address has been successfully verified.
                    </Alert>
                    <Spinner variant="purple" animation="border" />
                </React.Fragment>
            }
        </SimpleWin>
    )
}


export default VerifyAccount;
