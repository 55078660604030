import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { Picker } from '@components/forms';
import { useCollection, useModel } from '@lib/hooks';
import { DEFAULT_COUNTRY } from '@lib/geo-country';

import CountryCollection from './country-collection';
import CountryOptGroup from './country-opt-group';
import ManualAddressForm from './manual-address-form';
import PostcodeSearch from './postcode-search';

function AddressForm({ addressModel, children }) {
    const columns = children !== undefined ? 6 : 12;
    // @TODO: only call /srv?countries endpoint once
    const { collection } = useCollection(CountryCollection);
    const { fixedAttrs, model } = useModel(addressModel);
    const [useManualAddress, setUseManualAddress] = useState(model.isValid() ||
        fixedAttrs.country !== DEFAULT_COUNTRY);

    useEffect(() => {
        if (fixedAttrs.country !== DEFAULT_COUNTRY) setUseManualAddress(true);
    }, [fixedAttrs.country]);

    useEffect(() => {
        if (!useManualAddress) model.set('country', DEFAULT_COUNTRY);
    }, [useManualAddress, model]);

    return (
        <Row className="g-3">
            <Col lg={columns}>
                {useManualAddress &&
                    <ManualAddressForm addressModel={addressModel} />
                }

                {!useManualAddress &&
                    <div className="mb-2">
                        <PostcodeSearch
                            addressModel={addressModel}
                            countryCollection={collection}
                            onAddressSelected={() => setUseManualAddress(true)}
                            onError={() => setUseManualAddress(true)}
                        />
                    </div>
                }

                <Picker className="mb-2" bind={fixedAttrs} field="country">
                    <CountryOptGroup countryCollection={collection} />
                </Picker>

                <Button
                    variant="link"
                    size="sm"
                    className="mt-1"
                    onClick={() => setUseManualAddress(!useManualAddress)}
                >
                    {useManualAddress ? "Search for my postcode" : "Enter address manually"}
                </Button>
            </Col>
            {children &&
                <Col lg={columns}>
                    {children}
                </Col>
            }
        </Row>
    );
}


export default AddressForm;
