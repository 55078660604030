import { useState } from 'react';
import _ from 'underscore';


function useWizard({ steps }) {
    const [show, setShow] = useState(true);
    const [activeStep, setActiveStep] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const stepsList = _.compact(_.isArray(steps) ? steps : [steps]);
    const currentStep = stepsList[activeStep - 1];
    const isFirstStep = activeStep === 1;
    const isLastStep = activeStep === stepsList.length;
    const stepsMeta = stepsList.map((item, index) => ({
        number: index + 1,
        title: item.props.title,
        isActive: index === activeStep - 1,
    }));

    const nextStep = () => {
        if (isLastStep) {
            setShow(false);
            setIsComplete(true);
        } else {
            setActiveStep(activeStep + 1);
        }
    };
    const prevStep = () => setActiveStep(activeStep - 1);

    return {
        totalSteps: stepsList.length,
        activeStep,
        currentStep,
        isFirstStep,
        isLastStep,
        stepsMeta,
        nextStep,
        prevStep,
        isLoading,
        setLoading,
        show,
        setShow,
        isComplete,
    };
}


export default useWizard;
