import React from 'react';
import { Col, Row } from 'react-bootstrap';
import _ from 'underscore';

import { Picker } from '@components/forms';
import { useAccount, useModel, useWillUnmount } from '@lib/hooks';

import ExtensionsOptGroup from './extensions-opt-group';


function HardwareConfigurationItem({ basketModel, itemNum }) {
    const { model: accountModel } = useAccount();
    const { fixedAttrs } = useModel(basketModel.configuration, { fetch: true });
    const props = {};
    let label = 'Assign to';

    if (accountModel.canRouteToMaster) {
        props.label = 'Account';
    } else {
        label += ' extension';
    }

    useWillUnmount(() => accountModel.trigger('validate:hardware'));

    return (
        <Row className="align-items-center mt-3 mb-3">
            <Col xs={3} className="ms-4">{itemNum}. {label}:</Col>
            {_.range(basketModel.product.totalLines).map(line => (
                <Col key={line}>
                    <Picker bind={fixedAttrs} field={`extensionLine${line + 1}`}>
                        <Picker.NoneOption />
                        <ExtensionsOptGroup {...props} />
                    </Picker>
                </Col>
            ))}
        </Row>
    );
}


export default HardwareConfigurationItem;
