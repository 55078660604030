import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import fmt from '@lib/formatters';


function ResidentialBasketNumber({ number, onDelete }) {
    return (
        <Card bg="purple" text="light">
            <Card.Body className="d-flex justify-content-between">
                <div className="fs-5">Your number: <b>{fmt.phoneNumber(number)}</b></div>

                <div>
                    <Button
                        size="sm"
                        variant="light"
                        onClick={onDelete}
                    >
                        <Trash />
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}


export default ResidentialBasketNumber;
