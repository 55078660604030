import React from 'react';

import constants from '@lib/constants';

const title = 'Contact Us';


function ContactUsPage() {
    return (
        <React.Fragment>
            <h2>Contact Us</h2>
            <p>Sorry, we need to verify some details with you so we can finish setting up your account.</p>
            <p>Please contact customer support on <a href={`tel:${constants.VF_PHONE_NUMBER}`}>
                {constants.VF_PHONE_NUMBER}</a> or fill out our contact form <a target="_blank"
                href={`${constants.BASE_VF_URL}/contact`}>here</a>.</p>
        </React.Fragment>
    );
}


export default ContactUsPage;

export {
    title,
};
