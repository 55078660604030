import { useState } from 'react';
import _ from 'underscore';

import useDidMount from './use-did-mount';
import useWillUnmount from './use-will-unmount';


function useModel(modelOrModelCls, params={}) {
    const isClass = _.isFunction(modelOrModelCls);
    const { fetch=isClass, init: initialVals=null, context=null, loader=null } = params;

    const build = model => ({
        fixedAttrs: {
            ...model.attributes,
            cid: model.cid,
            model,
        },
        savedAttrs: {
            ...model._prevSavedAttrs,
            cid: model.cid,
            model,
        },
        model,
        canReset: model.canReset,
        canSave: model.canSave,
    })

    // Event ID is needed so that models can be reused without removing
    // listeners from other components on unmount
    const [eventId,] = useState(_.uniqueId('e'));
    const [model,] = useState(isClass ? new modelOrModelCls(initialVals) : modelOrModelCls);
    const [boundAttrs, setBoundAttrs] = useState(build(model));

    if (loader) loader.add(model, fetch);

    useDidMount(() => {
        model.context = model.context || context;
        model.on('change sync', () => setBoundAttrs(build(model)), eventId);

        if (!loader && fetch) model.fetch();
    });

    useWillUnmount(() => model.off(null, null, eventId));

    return boundAttrs;
}


export default useModel;
