import { useContext } from 'react';

import { AppContext } from '@lib/store';


function useSend() {
    const { send } = useContext(AppContext);
    return send;
}


export default useSend;
