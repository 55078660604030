import React from 'react';
import { Alert } from 'react-bootstrap';


function NotificationAlert({ title, children, ...alertProps }) {
    return (
        <Alert {...alertProps}>
            {title && <div className="fw-bold">{title}</div>}
            {children}
        </Alert>
    );
}


export default NotificationAlert;
