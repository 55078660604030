import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Pen } from 'react-bootstrap-icons';
import _ from 'underscore';

import AddressForm from '@content/contact-page/address-form';
import { useModel } from '@lib/hooks';


function BroadbandAddress({ addressModel, canEdit=false }) {
    const [editAddress, setEditAddress] = useState(false);
    const { fixedAttrs } = useModel(addressModel);
    const addressValues = _.compact(_.values(_.omit(fixedAttrs.model.parameters, 'country')));

    return (
        <React.Fragment>
            <p className="mb-2">
                <strong>Your Details</strong>
                {canEdit &&
                    <Button
                        variant="link"
                        className="ms-2 text-body"
                        onClick={() => setEditAddress(!editAddress)}
                    >
                        <Pen className="d-block" />
                    </Button>
                }
            </p>

            <div className="mb-3">
                {!editAddress &&
                    <address>
                        {addressValues.map((item, index) => (
                            <div key={index}>{item}</div>
                        ))}
                    </address>
                }

                {editAddress &&
                    <AddressForm addressModel={addressModel} />
                }
            </div>
        </React.Fragment>
    );
}


export default BroadbandAddress;
