import React from 'react';
import { Badge, Nav } from 'react-bootstrap';


function NavItem({ page, onClick, title, icon: Icon, label, children,
        badge=null, isRequired=false, ...props }) {
    return (
        <Nav.Item className="mb-1">
            <Nav.Link
                eventKey={page}
                onClick={onClick}
                className={`fw-bold text-${props.disabled ? 'dark' : 'body'}`}
                {...props}
            >
                <small className="d-flex flex-wrap align-items-center justify-content-between">
                    {title}
                    {isRequired &&
                        <Badge bg="danger" className="fw-normal">Required</Badge>
                    }
                </small>

                {Icon && <Icon className="me-2" />}
                <span className={`fs-6 align-middle ${!props.disabled && 'text-purple'}`}>{label}</span>

                {badge !== null &&
                    <Badge bg="secondary" className="ms-2">{badge}</Badge>
                }

                {children}
            </Nav.Link>
        </Nav.Item>
    );
}

export default NavItem;
