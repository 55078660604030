import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Pager } from '@components';
import { useCollection, usePagination } from '@lib/hooks';

import NumberResult from './number-result';


function NumberResults({ numberCollection }) {
    const { fixedList, collection } = useCollection(numberCollection);
    const pager = usePagination({ collection });

    return (
        <React.Fragment>
            <Row className="gy-4 justify-content-center">
                {fixedList.map(item => (
                    <Col key={item.cid} md={6}>
                        <NumberResult
                            baseSku={item.sku}
                            number={item.number}
                            type={item.type}
                        />
                    </Col>
                ))}
            </Row>

            <Pager {...pager} />
        </React.Fragment>
    );
}


export default NumberResults;
