import _ from 'underscore';

import { BaseModel } from '@lib/models';


class HardwareConfigurationModel extends BaseModel {
    static defaults = {
        id: null,
        extensionLine1: '',
        extensionLine2: '',
        extensionLine3: '',
    }

    validation = {
        extensionLine1: {
            fn: this.isValidExtension,
        },
        extensionLine2: {
            fn: this.isValidExtension,
        },
        extensionLine3: {
            fn: this.isValidExtension,
        },
    }

    get parameters() {
        const itemIds = this.accountModel?.getProducts(this.product).map(item => item.cid);
        const index = _.indexOf(itemIds, this.get('id'));
        const config = `config--${this.product?.uid}--${index}`;
        return _.range(this.product?.totalLines)
            .map(line => `${config}--${line + 1}=${this.get(`extensionLine${line + 1}`) || 'noconfig'}`)
            .join('&');
    }

    parse(data) {
        const extensions = _.difference(this.accountModel?.getExtensions(),
            this.getUsedExtensions());
        return _.range(this.product?.totalLines).reduce((obj, item) => {
            const key = `extensionLine${item + 1}`;
            const value = this.get(key);
            return { ...obj, [key]: extensions.length > 0 && !value ? extensions.shift() : value };
        }, { id: data.id || this.get('id') });
    }

    read(model, options) {
        if (options.accountModel) this.accountModel = options.accountModel;
        if (options.product) this.product = options.product;
        this.set(this.parse(options));
        this.trigger('sync');
    }

    reset() {
        this.set({ extensionLine1: '', extensionLine2: '', extensionLine3: '' });
    }

    isValidExtension(val) {
        if (val && !_.contains(this.accountModel?.getExtensions(), val)) {
            return 'This is an invalid extension';
        } else if (val && _.countBy(this.getUsedExtensions())[val] > 1) {
            return 'This is already assigned to a phone';
        }
    }

    getUsedExtensions() {
        const config = _.pluck(this.accountModel?.get('hardware'), 'configuration');
        return _.compact(_.flatten(config.map(item => item?.getExtensions())));
    }

    getExtensions() {
        const { extensionLine1, extensionLine2, extensionLine3 } = this.attributes;
        return [extensionLine1, extensionLine2, extensionLine3];
    }
}


export default HardwareConfigurationModel;
