import { BoundFormControl } from '@components/forms';
import { convertDate } from '@lib/utils';


function BoundDatePicker({ bind, field }) {
	const value = bind && bind[field];

	const onChange = (model, field) => {
		return ({ target }) => {
			const date = convertDate(target.value, 'YYYY-MM-DD', true);
			model.set(field, date.isValid() ? date : target.value);
		};
	};

	return (
		<BoundFormControl
			bind={bind}
			field={field}
			type="date"
			value={value?.isValid?.() ? value.format('YYYY-MM-DD') : value}
			pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
			onChange={onChange}
		/>
	);
}


export default BoundDatePicker;
