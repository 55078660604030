import { useContext } from 'react';

import { AppContext } from '@lib/store';
import { useModel } from '@lib/hooks';


function useAccount() {
    const { accountModel } = useContext(AppContext);
    const account = useModel(accountModel);

    return account;
}


export default useAccount;
