import { BoundFormControl } from '@components/forms';
import { shortTime } from '@lib/formatters';
import { convertTime } from '@lib/utils';


function BoundTimePicker({ bind, field, allowDateRollover=false }) {
	const value = bind && bind[field];

	const onChange = (model, field) => {
		return ({ target }) => {
			const time = convertTime(target.value, allowDateRollover);
			model.set(field, time.isValid() ? time : target.value);
		};
	};

	return (
		<BoundFormControl
			bind={bind}
			field={field}
			type="time"
			value={value?.isValid?.() ? shortTime(value) : value}
			pattern="[0-9]{2}:[0-9]{2}"
			onChange={onChange}
		/>
	);
}


export default BoundTimePicker;
