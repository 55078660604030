import _ from 'underscore';

import { BaseModel } from '@lib/models';

const RETRY_ON_ERRORS = ['invalid-csrf-token'];
const LOGOUT_ON_ERRORS = ['missing-auth-token', 'invalid-auth-token'];


class LegacyAPI {
    baseUrl = ''
    pendingRequests = 0
    events = new BaseModel()

    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    prepareData(data) {
        return _.pairs(data)
            .map(p => `${encodeURIComponent(p[0])}=${encodeURIComponent(p[1])}`)
            .join('&');
    }

    triggerEvent() {
        this.events.trigger('request');
        window.hasLoaded = this.pendingRequests === 0;
    }

    async get(path, responseType) {
        return this.sendRequest('GET', `api/srv?${path}`, {}, responseType, {});
    }

    async post(path, data, responseType, notifyModel) {
        const url = `api/upd?${path}`;
        const params = this.prepareData(data);

        if (notifyModel) {
            let formData = new FormData();
            _.pairs(data).forEach(p => formData.append(p[0], p[1]));
            notifyModel.trigger('upload', 0);
            return this.sendRequest('POST', url, formData, responseType, {}, notifyModel);
        }

        return this.sendRequest('POST', url, params, responseType, {
            'Content-Type': 'application/x-www-form-urlencoded',
        });
    }

    sendRequest(method, path, data, responseType, headers, notifyModel) {
        responseType = responseType ? responseType : 'json';
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open(method, this.baseUrl + path);
            req.withCredentials = true;

            _.pairs(headers)
                .map(p => req.setRequestHeader(p[0], p[1]));

            req.upload.onprogress = e => {
                const percent = Math.ceil((e.loaded / e.total) * 100);
                if (notifyModel) notifyModel.trigger('upload', percent);
            };

            req.onload = e => {
                this.pendingRequests--;
                this.triggerEvent();

                if (req.status === 200) {
                    if (req.responseText.indexOf('"authfirst"') > -1) {
                        this.events.trigger('server.authenticate.destroy', 'authfirst');
                        return req.onerror();
                    } else if (req.responseText.indexOf('error') === 2) {
                        try {
                            return req.onerror(
                                _.rest(JSON.parse(req.responseText), 1));
                        } catch (e) {
                            return req.onerror();
                        }
                    }

                    switch (responseType) {
                        case 'json':
                            resolve(JSON.parse(req.responseText));
                            break;

                        case 'text':
                        default:
                            resolve(req.responseText);
                    }

                    if (notifyModel) notifyModel.trigger('upload.success');
                } else {
                    req.onerror();
                }
            };

            req.onerror = e => {
                if (notifyModel) notifyModel.trigger('upload.error');
                reject(e);
            };

            this.pendingRequests++;
            this.triggerEvent();
            req.send(data);
        });
    }
}


class API extends LegacyAPI {
    hasSession = false
    token = null

    init() {
        return new Promise(async (resolve, reject) => {
            if (!this.hasSession) {
                await this.acquireSession();
            }

            if (!this.token) {
                await this.acquireCsrfToken();
            }

            resolve();
        });
    }

    reset() {
        this.hasSession = false;
        this.token = null;
    }

    acquireSession() {
        return new Promise((resolve, reject) => {
            this._post('__session__', {})
                .then(() => {
                    this.hasSession = true;
                    resolve();
                })
                .catch(err => {
                    if (err.error === 'invalid-auth-token') {
                        this.hasSession = false;
                        reject();
                    } else {
                        this.hasSession = true;
                        resolve();
                    }
                });
        });
    }

    acquireCsrfToken() {
        return new Promise((resolve, reject) => {
            this._get('__token__')
                .then(data => {
                    this.token = data;
                    resolve();
                })
                .catch(err => {
                    this.token = null;
                    reject();
                });
        });
    }

    async get(path, responseType) {
        await this.init();
        return this._get(path, responseType);
    }

    async post(path, data, responseType) {
        await this.init();
        return this._post(path, data, responseType);
    }

    async put(path, data, responseType) {
        await this.init();
        return this._put(path, data, responseType);
    }

    async delete(path) {
        await this.init();
        return this._delete(path);
    }

    _get(path, responseType) {
        return this.sendRequest('GET', path, {}, responseType);
    }

    _post(path, data, responseType) {
        return this.sendRequest('POST', path, this.prepareData(data), responseType, {
            'X-CSRF-Token': this.token,
            'Content-Type': 'application/x-www-form-urlencoded',
        });
    }

    _put(path, data, responseType) {
        return this.sendRequest('PUT', path, JSON.stringify(data), responseType, {
            'X-CSRF-Token': this.token,
            'Content-Type': 'application/json',
        });
    }

    _delete(path) {
        return this.sendRequest('DELETE', path, null, undefined, {
            'X-CSRF-Token': this.token,
        });
    }

    sendRequest(method, path, data, responseType, headers, refreshTokenOnError=true) {
        responseType = responseType ? responseType : 'text';
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open(method, this.baseUrl + path);
            req.withCredentials = true;

            _.pairs(headers)
                .map(p => req.setRequestHeader(p[0], p[1]));

            req.onload = () => {
                if (req.status === 200) {
                    switch (responseType) {
                        case 'json':
                            resolve(JSON.parse(req.responseText));
                            break;

                        case 'text':
                        default:
                            resolve(req.responseText);
                    }
                } else if (req.status === 204) {
                    resolve();
                } else {
                    let error = { error: 'undefined', message: req.statusText, data: null };
                    try {
                        error = JSON.parse(req.responseText);
                    } catch (e) { }

                    if (_.contains(LOGOUT_ON_ERRORS, error.error)) {
                        this.events.trigger('server.authenticate.destroy', error.error);
                        reject(error);
                    } else if (refreshTokenOnError && _.contains(RETRY_ON_ERRORS, error.error)) {
                        this.acquireCsrfToken()
                            .then(() => {
                                headers['X-CSRF-Token'] = this.token;
                                this.sendRequest(method, path, data, responseType, headers, false)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(err => {
                                        reject(err);
                                    });
                            });
                    } else {
                        reject(error);
                    }
                }

                this.pendingRequests--;
                this.triggerEvent();
            };
            req.onerror = (e) => {
                reject(`Error (${method} request): ${e}`);
            };

            this.pendingRequests++;
            this.triggerEvent();
            req.send(data);
        });
    }
}


export default {
    v0: new LegacyAPI(process.env.REACT_APP_API_LEGACY_BASE_URL),
    v1: new API(process.env.REACT_APP_API_BASE_URL),
}
