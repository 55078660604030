import React from 'react';

import fmt from '@lib/formatters';


function PriceLabel({ price }) {
    const isNegative = price < 0;

    return (
        <React.Fragment>
            {isNegative &&
                <span>-&pound;{fmt.price(Math.abs(price))}</span>
            }

            {!isNegative &&
                <span>&pound;{fmt.price(price)}</span>
            }
        </React.Fragment>
    );
}


export default PriceLabel;
