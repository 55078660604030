import React from 'react';
import { Col, Row } from 'react-bootstrap';


function ButtonBar({ children }) {
    const childArr = React.Children.toArray(children);

    if (childArr.length === 0) return '';

    let left, right;

    if (childArr.length === 1) {
        right = childArr[0];
    } else {
        left = childArr[0];
        right = childArr[1];
    }

    return (
        <Row>
            <Col>
                {left}
            </Col>
            <Col className="text-end">
                {right}
            </Col>
        </Row>
    );
};


export default ButtonBar;
