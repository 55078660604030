const ID_GROUPS = 'group';
const ID_IVR_MULTI = 'ivrMultiLevel';
const ID_IVR_SINGLE = 'ivrSingleLevel';
const ID_MIXED_MINUTES = 'mixedMinutes';
const SERVICES_WITH_MULTIPLE = [ID_GROUPS, ID_IVR_MULTI, ID_IVR_SINGLE];

const SERVICES_BUSINESS = [
    {
        id: ID_GROUPS,
        name: 'Call Groups',
        url: 'PB_Call_Groups.php',
    },
    {
        id: 'callbar',
        name: 'Call Barring',
        url: 'PB_Call_Barring.php',
    },
    {
        id: 'queue',
        name: 'Call Queue',
        url: 'PB_Call_Queuing.php',
    },
    {
        id: 'cmbpro',
        name: 'Call Me Back Pro',
        url: 'PB_Call_Me_Back.php',
    },
    {
        id: ID_IVR_SINGLE,
        name: 'IVRs (Single Menu)',
        url: 'PB_Virtual_Switchboard_IVR.php',
    },
    {
        id: ID_IVR_MULTI,
        name: 'IVRs (Multi Menu)',
        url: 'PB_Virtual_Switchboard_IVR.php',
    },
    {
        id: 'cloudcallrecording',
        name: 'Cloud Call Recording',
        url: 'Call_Recording.php',
    },
    {
        id: 'custommoh',
        name: 'Custom Music on Hold',
        url: 'voipfone-custom-music-on-hold.php',
    },
];

const SERVICES_RESIDENTIAL = [
    {
        name: 'Keep your number, or get a new one',
    },
    {
        name: 'Voicemail',
    },
    {
        name: 'Call Forwarding',
    },
    {
        name: 'Call Blocking',
    },
];

export {
    ID_GROUPS,
    ID_IVR_MULTI,
    ID_IVR_SINGLE,
    ID_MIXED_MINUTES,
    SERVICES_BUSINESS,
    SERVICES_RESIDENTIAL,
    SERVICES_WITH_MULTIPLE,
};
