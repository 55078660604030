import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

import { useSend } from '@lib/hooks';

const title = 'Verify Your Email';


function VerifyEmailPage() {
    const [hasResent, setHasResent] = useState(false);
    const send = useSend();

    const resendEmail = () => {
        send('RESEND');
        setHasResent(true);
    };

    return (
        <React.Fragment>
            <h2>Verify Your Email</h2>

            <p>Thank you - we now have everything we need. Please check your inbox
            and follow the verification link to start using your account.</p>

            {hasResent && (
                <Alert variant="success"><small>We re-sent the verification link to your
                registered email address. If you still haven't received it after a few minutes, please
                check your junk folder.</small></Alert>
            )}

            {!hasResent && (
                <p><Button variant="link" onClick={resendEmail}>
                    I didn't receive the email
                </Button></p>
            )}
        </React.Fragment>
    );
}


export default VerifyEmailPage;

export {
    title,
};
