import React from 'react';
import _ from 'underscore';

import { useBasket, useCheckViewport } from '@lib/hooks';

import BasketDesktop from './basket-desktop';
import BasketMobile from './basket-mobile';


function Basket() {
    const basket = useBasket();
    const groupedBasket = _.groupBy(basket.items, item => item.product.uid);
    const basketItems = Object.keys(groupedBasket).map(key => ({
        product: groupedBasket[key][0].product,
        quantity: groupedBasket[key].reduce((sum, item) => sum + item.quantity, 0),
    }));
    const showMobileView = useCheckViewport({ width: 1280 });

    return (
        <React.Fragment>
            {showMobileView &&
                <BasketMobile items={basketItems} />
            }

            {!showMobileView &&
                <BasketDesktop items={basketItems} />
            }
        </React.Fragment>
    );
}


export default Basket;
