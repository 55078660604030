import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import fmt from '@lib/formatters';


function BasketNumber({ number, onDelete }) {
    return (
        <ListGroup.Item
            as="li"
            variant="light"
            className="d-flex justify-content-between align-items-center"
        >
            <div className="fw-bold text-body">{fmt.phoneNumber(number)}</div>
            <Button
                size="sm"
                variant="light"
                onClick={onDelete}
            >
                <Trash />
            </Button>
        </ListGroup.Item>
    );
}


export default BasketNumber;
