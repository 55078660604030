import React from 'react';
import { ListGroup } from 'react-bootstrap';
import _ from 'underscore';

import BasketNumber from '@content/number-page/basket-number';
import { useAccount } from '@lib/hooks';


function PortingNumbers() {
    const { model: accountModel, fixedAttrs: { portNumbers } } = useAccount();
    const hasPortNumber = portNumbers.length > 0;

    return hasPortNumber ? (
        <ListGroup>
            {portNumbers.map(item => (
                <BasketNumber
                    key={item}
                    number={item}
                    onDelete={() => accountModel.set('portNumbers', _.without(portNumbers, item))}
                />
            ))}
        </ListGroup>
    ) : '';
}


export default PortingNumbers;
