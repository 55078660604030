import React from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { BoxArrowInRight, Gift, Headphones, PersonCircle, StarFill, TelephoneInbound, Wifi } from 'react-bootstrap-icons';
import _ from 'underscore';

import fmt from '@lib/formatters';
import { useAccount, useBasket, useSend, useStateContext } from '@lib/hooks';

import LogoutButton from './logout-button';
import NavItem from './nav-item';

import './main-win.scss';


function MainWin({ page, children }) {
    const params = useParams();
    const send = useSend();
    const {
        fixedAttrs: { plan, buyNumbers, portNumbers, broadband },
        savedAttrs: { firstname, surname },
        model: accountModel,
    } = useAccount();
    const context = useStateContext();
    const basket = useBasket();

    const isTabDisabled = (pageName) => {
        return page !== pageName && !_.contains(context.visitedPages, pageName);
    };

    return (
        <Container className="mt-4">
            <Row className="gy-2 gx-4 text-center text-md-start align-items-center">
                <Col md={3}>
                    <img
                        loading="lazy"
                        className="logo w-100 h-auto"
                        alt="Welcome to Voipfone"
                        title="Welcome to Voipfone"
                        src="/logo.png"
                    />
                </Col>
                <Col>
                    <h1 className="text-white display-5">Getting Started</h1>
                </Col>
                <Col md={2} className="text-end d-none d-md-block">
                    <LogoutButton />
                </Col>
            </Row>

            <Row className="g-0">
                <Col md={3}>
                    <Navbar expand="md">
                        <Row className="w-100 flex-grow-1 d-md-none align-items-center">
                            <Col xs={6}>
                                <Navbar.Toggle aria-controls="main-menu" />
                            </Col>
                            <Col xs={6} className="text-end">
                                <LogoutButton />
                            </Col>
                        </Row>
                        <Navbar.Collapse id="main-menu">
                            <Nav variant="pills" activeKey={page} className="flex-column w-100 mt-3 mb-3">
                                <NavItem
                                    page="intro-page"
                                    onClick={() => send('NAVIGATE-INTRO')}
                                    title="Account"
                                    icon={StarFill}
                                    label={params.account}
                                    disabled={context.complete}
                                />

                                <NavItem
                                    page="plan-page"
                                    onClick={() => send('NAVIGATE-PLAN')}
                                    title="My Plan"
                                    icon={Gift}
                                    label={plan?.product.shortName || '--'}
                                    badge={plan ? plan.quantity : null}
                                    disabled={isTabDisabled('plan-page')}
                                    isRequired={!plan}
                                />

                                {broadband.length > 0 &&
                                    <NavItem
                                        page="broadband-page"
                                        onClick={() => send('NAVIGATE-BROADBAND')}
                                        title="Broadband"
                                        icon={Wifi}
                                        label={`${broadband.length} order${broadband.length === 1 ? '' : 's'}`}
                                        disabled={isTabDisabled('broadband-page')}
                                    />
                                }

                                {context.showPorting && <NavItem
                                    page="porting-page"
                                    onClick={() => send('NAVIGATE-PORTING')}
                                    title="Port Numbers"
                                    icon={BoxArrowInRight}
                                    label={`${portNumbers.length} number${portNumbers.length === 1 ? '' : 's'}`}
                                    disabled={isTabDisabled('porting-page')}
                                    isRequired={accountModel.isResidential && portNumbers.length === 0}
                                />}

                                {(accountModel.isBusiness || (!context.showPorting || buyNumbers.length > 0)) &&
                                    <NavItem
                                        page="number-page"
                                        onClick={() => send('NAVIGATE-NUMBER')}
                                        title="Numbers"
                                        icon={TelephoneInbound}
                                        label={`${buyNumbers.length} number${buyNumbers.length === 1 ? '' : 's'}`}
                                        disabled={isTabDisabled('number-page')}
                                        isRequired={accountModel.isResidential && buyNumbers.length === 0}
                                    />
                                }

                                {accountModel.hasHardware && <NavItem
                                    page="hardware-page"
                                    onClick={() => send('NAVIGATE-HARDWARE')}
                                    title="Hardware"
                                    icon={Headphones}
                                    label={`${accountModel.hardwareCount} product${accountModel.hardwareCount === 1 ? '' : 's'}`}
                                    disabled={isTabDisabled('hardware-page')}
                                />}

                                <NavItem
                                    page="contact-page"
                                    onClick={() => send('NAVIGATE-CONTACT')}
                                    title="Contact Details"
                                    icon={PersonCircle}
                                    label={firstname || surname ? `${firstname} ${surname}` : '--'}
                                    disabled={isTabDisabled('contact-page')}
                                    isRequired={!accountModel.isValid()}
                                />

                                {accountModel.basketCount > 0 &&
                                    <NavItem
                                        page="payment-page"
                                        onClick={() => send('NAVIGATE-PAYMENT')}
                                        disabled={isTabDisabled('payment-page')}
                                        title="Paying Today"
                                        label={<span>&pound;{fmt.price(basket.totalIncVat())}</span>}
                                    >
                                        <br/>
                                        <span className="text-secondary">
                                            <small>Monthly: &pound;{fmt.price(basket.totalRenewalPriceIncVat())}</small>
                                        </span>
                                    </NavItem>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>

                <Col>
                    <Container className="p-4 h-100 bg-white rounded-3">
                        {children}
                    </Container>
                </Col>
            </Row>

            <Row>
                <Col md={3} />
                <Col className="small p-2 text-center text-white">
                    Copyright 2004 - {dayjs().format('YYYY')},
                    iNet Telecoms&reg; Ltd All rights reserved
                </Col>
            </Row>
        </Container>
    );
}

export default MainWin;
