import React from 'react';
import { Col, Row } from 'react-bootstrap';


function FormRow({ children }) {
    let labelCmp;
    let inputCmp;

    if (children && children.length > 0) {
        inputCmp = children[children.length - 1];
    } else {
        inputCmp = <br />;
    }

    if (children && children.length > 1) {
        labelCmp = children[0];
    } else {
        labelCmp = <br />;
    }

    return (
        <Row>
            <Col sm={3}>
                {labelCmp}
            </Col>
            <Col sm={9}>
                {inputCmp}
            </Col>
        </Row>
    );
}


export default FormRow;
