import React from 'react';

const NUMBER_TYPES = {
    '0207': '0207 London',
    '0208': '0208 London',
    '03003': '030 Non Profit',
    '03302': '033 National',
    '0800': '0800 Freephone',
    '0843': '0843 Non-Geographic',
    '0845': '0845 Non-Geographic',
    '0870': '0870 Non-Geographic',
};


function NumberTypeOptGroup() {
    return (
        <optgroup label="Number Types">
            <option key="uk" value="">UK Local</option>

            {Object.keys(NUMBER_TYPES).map(key => (
                <option
                    key={key}
                    value={key}
                >{NUMBER_TYPES[key]}</option>
            ))}
        </optgroup>
    );
}


export default NumberTypeOptGroup;
