import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { BoxArrowLeft } from 'react-bootstrap-icons';

import { useSend } from '@lib/hooks';


function LogoutButton() {
    const [isDisabled, setDisabled] = useState(false);
    const send = useSend();

    return (
        <Button
            size="sm"
            variant="link"
            className="text-white text-decoration-none"
            disabled={isDisabled}
            onClick={() => {
                setDisabled(true);
                send('LOGOUT');
            }}
        >
            <BoxArrowLeft /> Logout
        </Button>
    );
}

export default LogoutButton;
