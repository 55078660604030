import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'underscore';

export const DEFAULT_ERROR_MSG = "An error occurred.  Please try again.";


export function getErrorMsg(reason, errorMessages={}) {
    if (_.has(errorMessages, reason)) {
        return errorMessages[reason];
    } else if (_.has(errorMessages, '')) {
        return errorMessages[''];
    } else {
        return DEFAULT_ERROR_MSG;
    }
}


export function isAlpha(str) {
    return Boolean(str && str.match(/^[a-zA-Z]+$/));
}


export function convertType(val, dataType) {
    switch (dataType) {
        case 'bool': return _.contains(['1', 'on', 'true', 'yes'], String(val));
        case 'int': return parseInt(val);
        case 'float': return parseFloat(val);
        case 'str': return String(val);
        default: return val;
    }
}


export function convertDate(val, format='YYYY-MM-DD') {
    let dateString = val;

    if (_.isObject(val)) {
        const { year, month, day } = val;
        dateString = `${year}-${month.toString().padStart(2, 0)}-${day.toString().padStart(2, 0)}`;
    }

    dayjs.extend(customParseFormat);
    return dayjs(dateString, format, true);
}


export function convertTime(val, allowDateRollover=false, format='HH:mm') {
    const date = convertDate(val, format);
    return date.isValid() && allowDateRollover && date.hour() === 0 &&
        date.minute() === 0 ? date.add(1, 'day') : date;
}


export function formChangeHandler(model, field, dataType) {
    return ({ target }) => {
        if (!target) {
            return;
        } else if (target.files) {
            model.set(field, target.files[0]);
        } else if (_.has(target, 'checked')) {
            model.set(field, target.checked);
        } else {
            model.set(field, convertType(target.value, dataType));
        }
    }
}


export default {
    DEFAULT_ERROR_MSG,
    convertType,
    convertDate,
    convertTime,
    formChangeHandler,
    getErrorMsg,
    isAlpha,
};
